import { UntypedFormControl } from '@angular/forms';

export function matchOtherValidator(
  otherControlName: string,
  controlLabel: string,
  otherControlLabel: string
) {
  let thisControl: UntypedFormControl;
  let otherControl: UntypedFormControl;

  return function matchOtherValidate(control: UntypedFormControl) {
    if (!control.parent) {
      return null;
    }

    // Initializing the validator.
    if (!thisControl) {
      thisControl = control;
      otherControl = control.parent.get(otherControlName) as UntypedFormControl;
      if (!otherControl) {
        throw new Error(
          'matchOtherValidator(): other control is not found in parent group'
        );
      }
      otherControl.valueChanges.subscribe(() => {
        thisControl.updateValueAndValidity();
      });
    }

    if (!otherControl) {
      return null;
    }
    if (otherControl.value !== thisControl.value) {
      return {
        match: `${controlLabel} and ${otherControlLabel} must be equals`,
      };
    }

    return null;
  };
}
