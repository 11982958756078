import { UserService } from 'src/app/services/user.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { catchError, first, map, of } from 'rxjs';
import { State } from 'src/app/models/state';
import { AuthService } from 'src/app/services/auth.service';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

@UntilDestroy()
@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent implements OnInit {
  @Input() isAdmin = false;

  profilePicture = '';

  state = State.INITIAL;
  State = State;

  formRegister: FormGroup;
  update = false;
  next: string | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private userService: UserService
  ) {
    this.formRegister = this.formBuilder.group({
      profilePicture: [''],
      fullName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      countryCode: [''],
      zipCode: [''],
      taxID: [''],
      city: [''],
      streetAddress: [''],
      country: [''],
      notify: [false],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(first()).subscribe((params) => {
      const next = params?.next;
      this.next = next && next !== '/' ? next : null;
    });
    this.update = this.activatedRoute.snapshot.data.update ?? false;

    if (!this.update) {
      this.watchUserRegistered();
    } else {
      this.patchFormValue();
    }
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (!file) {
      return;
    }

    this.previewImagem(file).then((base64) => {
      this.profilePicture = base64;
    });

    this.userService.setProfilePicture({ image: file }).subscribe((result) => {
      // console.log(result);
    });
  }

  previewImagem(file: any): Promise<string> {
    const fileReader = new FileReader();
    return new Promise<string>((resolve) => {
      fileReader.readAsDataURL(file);
      fileReader.onload = (e: any) => resolve(e.target.result);
    });
  }

  togglePassword(password: HTMLElement): void {
    const type =
      password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);
  }

  onSubmit(): void {
    this.formRegister.markAllAsTouched();
    if (this.formRegister.invalid) {
      this.toastr.error(
        'Please fill in all required fields.',
        'Invalid Data.',
        {}
      );
      return;
    }

    const phoneNumberUtil = PhoneNumberUtil.getInstance();

    const phoneNumber: string = this.formRegister.value.phoneNumber;
    const countryCode: string = this.formRegister.value.countryCode;

    try {
      const isValid = phoneNumberUtil.isValidNumber(
        phoneNumberUtil.parse(`${countryCode} ${phoneNumber}`)
      );
      if (!isValid) {
        this.toastr.error(
          'Please, insert a valid phone number.',
          'Invalid Data.',
          {}
        );
        return;
      }
    } catch {
      this.toastr.error(
        'Please, insert a valid phone number.',
        'Invalid Data.',
        {}
      );
      return;
    }

    this.state = State.LOADING;

    this.formRegister.value.phoneNumber = countryCode + phoneNumber

    const action$ = this.update
      ? this.authService.updateProfile(this.formRegister.value)
      : this.authService
          .signUpWithSystem(this.formRegister.value)
          .pipe(map((response) => !!response));

    action$.pipe(catchError((err) => of(null))).subscribe(() => {
      this.state = State.LOADED;
      if (this.update) {
        this.toastr.success('Your profile was updated successfully');
      }
      if (this.next) {
        this.router.navigate([this.next]);
      }
    });
  }

  private watchUserRegistered(): void {
    this.authService.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      if (user) {
        this.router.navigate([this.next ?? '/']);
      }
    });
  }

  private patchFormValue(): void {
    this.formRegister.get('password')?.clearValidators();
    this.formRegister.get('confirmPassword')?.clearValidators();
    this.formRegister.get('password')?.disable();
    this.formRegister.get('confirmPassword')?.disable();

    this.authService.me().subscribe((user) => {
      if (!user) {
        return;
      }
      if (user.pictureUrl) {
        this.profilePicture = user.pictureUrl;
      }
      this.formRegister.patchValue({
        ...user,
        fullName: user.fullName || user.name || '',
      });
    });
  }
}
