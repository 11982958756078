import {Component} from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  features = [
    {
      frontImage: 'assets/images/product-photo-front.png',
      backImage: 'assets/images/product-photo-back.png',
      title: 'product photos',
      text: 'We capture the essence of the item with impeccable detail and visual appeal. The composition is carefully crafted to showcase the product\'s key features, enticing potential buyers at a glance. The lighting is expertly executed, highlighting the product\'s textures, colors, and intricate design elements.'
    },
    {
      frontImage: 'assets/images/model-photos-front.jpg',
      backImage: 'assets/images/model-photos-back.jpg',
      title: 'model photos',
      text: 'The on-model product photos feature a model wearing the product, adding a human element and bringing the item to life. The model showcases the product\'s fit, style, and versatility, allowing customers to visualize how it would look and feel on themselves. The on-model photos provide a relatable and engaging perspective, helping customers make informed purchasing decisions and connecting them with the brand\'s vision.'
    },
    {
      frontImage: 'assets/images/lifestyle-front.jpg',
      backImage: 'assets/images/lifestyle-back.jpg',
      title: 'lifestyle shoots',
      text: 'Lifestyle product photos capture the product in real-life scenarios, showcasing its practicality and enhancing its desirability. These photos go beyond traditional product shots, presenting the item in context, such as a home, outdoor setting, or everyday use scenario. The aim is to evoke a sense of lifestyle and demonstrate how the product seamlessly integrates into the customer\'s life, addressing their needs and enhancing their experiences. These captivating photos invite viewers to imagine themselves enjoying the product\'s benefits and create an emotional connection that goes beyond its features and aesthetics.'
    }
  ];
  images = [
    '../assets/images/studio-1.png',
    '../assets/images/studio-2.png'
  ];
  gallery = [
    {
      img_src: 'assets/images/clients/client-1.png',
    },
    {
      img_src: 'assets/images/clients/client-2.png',
    },
    {
      img_src: 'assets/images/clients/client-3.png',
    },
    {
      img_src: 'assets/images/clients/client-4.png',
    },
    {
      img_src: 'assets/images/clients/client-5.jpg',
    },
    {
      img_src: 'assets/images/clients/client-6.png',
    },
    {
      img_src: 'assets/images/clients/client-7.png',
    },
    {
      img_src: 'assets/images/clients/client-8.png',
    },
    {
      img_src: 'assets/images/clients/client-9.jpeg',
    },
    {
      img_src: 'assets/images/clients/client-10.png',
    },
    {
      img_src: 'assets/images/clients/client-11.jpg',
    },
    {
      img_src: 'assets/images/clients/client-12.png',
    },
    {
      img_src: 'assets/images/clients/client-13.png',
    },
    {
      img_src: 'assets/images/clients/client-14.png',
    },
    {
      img_src: 'assets/images/clients/client-15.png',
    },
    {
      img_src: 'assets/images/clients/client-16.png',
    },
    {
      img_src: 'assets/images/clients/client-17.jpg',
    },
    {
      img_src: 'assets/images/clients/client-18.jpg',
    },
    {
      img_src: 'assets/images/clients/client-19.png',
    },
    {
      img_src: 'assets/images/clients/client-20.jpeg',
    },
  ];
}
