import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'validationMessage',
})
export class ValidationMessagePipe implements PipeTransform {
  transform(
    errors: any,
    label: string = '',
    compareLabel: string = ''
  ): Array<string> {

    if (!errors) {
      return [];
    }
    return Object.keys(errors).map((error) => {
      switch (error) {
        case 'required':
          return `${label} is required`;
        case 'min_selected':
          return errors?.[error];
        case 'invalidFormat':
          return errors?.[error];
        case 'invalidSize':
          return errors?.[error];
        case 'match':
          return errors?.[error];
        case 'minlength':
          return `at least ${errors?.[error]?.requiredLength} characters`;
        case 'email':
          return `invalid email address`;
        default:
          console.error(`Erro Desconhecido: ${error}`, errors?.[error]);
          return 'invalid field';
      }
    });
  }
}
