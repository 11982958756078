<div class="container">
  <h2 class="text-black  my-4 mb-5">Review Your Order</h2>
  <div class="row gx-2">
    <div class="col-12">
      <div class="information">
        <small>Pick a Service</small>
        <p>{{order?.service}}</p>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="information">
        <small>Quantity of Items Shipped</small>
        <p>{{order?.quantityItemShipped}}</p>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="information">
        <small>Num Of Photos Per Product</small>
        <p>{{order?.quantityPhotosPerProduct}}</p>
      </div>
    </div>
    <div class="col-12">
      <div class="information">
        <small>Select Studio - Type of Photo</small>
        <p>{{(order?.typeOfPhotos ?? '') | arrayToString}}</p>
      </div>
    </div>
    <div class="col-12">
      <div class="information">
        <small>Type of Product</small>
        <p>{{(order?.typeOfProducts ?? '') | arrayToString}}</p>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="information">
        <small>Image Dimensions</small>
        <p>{{order?.imageDimension}}</p>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="information">
        <small> Color Background & Shadow</small>
        <p>{{order?.colorBackgroundAndShadow}}</p>
      </div>
    </div>
    <div class="col-12">
      <div class="information">
        <small>Extras</small>
        <p>{{(order?.extras?.type ?? '-') | arrayToString}}</p>
      </div>
    </div>
    <div class="col-12">
      <div class="information">
        <small>References</small>
        <div class="d-flex gap-2 flex-wrap">
          <app-media-preview *ngFor="let file of order?.extras?.images" [file]="file"></app-media-preview>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="information">
        <small>Link</small>
        <p class="text-break">{{order?.extras?.link ?? '-'}}</p>
      </div>
    </div>
    <div class="col-12">
      <div class="information">
        <small>Briefing</small>
        <p>{{order?.extras?.briefing ?? '-'}}</p>
      </div>
    </div>
  </div>
</div>
<div class="d-flex p-3 justify-content-between justify-content-lg-end gap-3 btn-form align-content-center">
  <a type="button" class="btn btn-lg btn-outline-black btn-back" [routerLink]="['/order']">
    Back
  </a>

  <button type="button" class="btn btn-lg btn-black btn-next text-nowrap" [disabled]="state === State.LOADING"
          (click)="createOrder()">
    {{state === State.LOADING ? 'Creating Order' : 'Create Order'}}
  </button>
</div>
