import {AbstractControl, FormArray, ValidationErrors, ValidatorFn,} from '@angular/forms';

export function atLeastOneSelectedValidator(minRequired = 1): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!(control instanceof FormArray)) {
      return null;
    }
    const selectedItens = control.controls.filter(
      (item) => item.value?.selected
    );
    return selectedItens.length >= minRequired
      ? null
      : {min_selected: `Select at least ${minRequired} item`};
  };
}
