<section class="py-5">
  <article class="container">
    <h2 class="text-black  mb-4">
      Technology and personalized service for all purposes!
    </h2>
    <p style="font-size: 20px;" class="card-text text-gray mb-4">
      Here you can photograph over 300 items a day.<br>Each item is ironed and prepared for photography.<br>Each image is retouched + adjusting size, background and shadow to your website.<br>Within 48 hours, the photos are with you.<br>We are your HOUSE IN studio, without the surrounding headache.
    </p>
    <div class="row mb-5">
      <div class="col-12 col-md-6 col-lg-4 mb-4" *ngFor="let feature of works_features" >

       <app-how-it-works-card class="h-100" [img_src]="feature.img_src" [title]="feature.title" [text]="feature.text"></app-how-it-works-card>
      </div>
    </div>
  </article>
</section>
