import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function FileFormatValidator(allowedFormats: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const file = control.value as File;


      if (!allowedFormats.includes(file.type)) {
        console.log(file);
        return { invalidFormat: 'Invalid file format. Only JPG, JPEG, PNG and MP4 files are allowed.' };
      }
    }
    return null;
  };
}
