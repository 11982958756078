import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {first} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {State} from '../../models/state';
import {catchError, map, of} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@UntilDestroy()
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {

  next: string | null = null;

  constructor(
    private toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    

  }

}

