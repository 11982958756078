<a href="https://wa.me/972542051778" target="_blank" class="chat">
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
    <defs>
      <style>.bg {
        fill: #60ce73;
        fill-rule: evenodd;
      }

      .icon {
        fill: #fff;
      }</style>
    </defs>
    <path class="bg"
          d="M25.2,0H97.68a25.27,25.27,0,0,1,25.2,25.2V97.68a25.27,25.27,0,0,1-25.2,25.2H25.2A25.27,25.27,0,0,1,0,97.68V25.2A25.27,25.27,0,0,1,25.2,0Z"/>
    <path class="icon"
          d="M95,87.38c5.29-3.91,8.73-9.65,8.73-16.09,0-11.71-11.49-21.14-25.5-21.14S52.94,59.58,52.94,71.29,64.2,92.43,78.22,92.43a31.23,31.23,0,0,0,8.27-1.15,2.68,2.68,0,0,1,2.07.23l5.74,3.22c.69.46,1.38,0,1.15-.92l-1.38-5A1.55,1.55,0,0,1,95,87.38ZM69.72,67.85a3.8,3.8,0,0,1-.89.11,3.45,3.45,0,0,1,0-6.89,3.8,3.8,0,0,1,.89.11,3.45,3.45,0,0,1,0,6.67Zm17,0a3.79,3.79,0,0,1-.88.11,3.45,3.45,0,1,1,0-6.89,3.79,3.79,0,0,1,.88.11,3.45,3.45,0,0,1,0,6.67Zm-37-41.13C33,26.72,19.4,38.21,19.4,52.22c0,7.59,3.9,14.48,10.34,19.07a2,2,0,0,1,.92,2.3l-1.61,5.52a1.08,1.08,0,0,0,1.38,1.37l6.89-3.9a3.19,3.19,0,0,1,2.53-.46A34,34,0,0,0,51.57,77.5C46.05,58.2,65.12,47.17,79.83,48.09c-2.3-12-14.94-21.37-30.1-21.37ZM39.62,48.09a4.58,4.58,0,0,1-1,.11,4.14,4.14,0,1,1,0-8.27,4,4,0,0,1,1,.12,4.13,4.13,0,0,1,0,8Zm20.45,0a4.58,4.58,0,0,1-1,.11,4.14,4.14,0,1,1,0-8.27,4,4,0,0,1,1,.12,4.14,4.14,0,0,1,0,8Zm0,0Z"/>
  </svg>

</a>
