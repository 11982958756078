import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {OrderService} from '../services/order.service';

@Injectable({
  providedIn: 'root'
})
export class HasOrderToReviewGuard implements CanActivate {
  constructor(private orderService: OrderService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const order = !!this.orderService.getOrder();
    if (order) {
      return true;
    }
    return of(this.router.parseUrl('/order'));

  }

}
