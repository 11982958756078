<div class="d-flex gap-2">
  <ng-select
    (ngModelChange)="updateCountryCode($event)"
    [(ngModel)]="countryCode"
    [clearable]="false"
    [disabled]="disabled"
    [items]="countries$ | async"
    [formControl]="countryControl"
    bindLabel="code"
    bindValue="code"
  >
    <ng-template ng-label-tmp let-item="item">
      <div class="text-nowrap d-flex gap-2 align-items-center">
        <img
          height="16"
          width="16"
          [src]="'/assets/flags/' + item.alpha_2 + '.svg'"
        />
        {{ item.code }}
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="text-nowrap d-flex gap-2 align-items-center">
        <img
          height="16"
          width="16"
          [src]="'/assets/flags/' + item.alpha_2 + '.svg'"
        />
        {{ item.code }}
      </div>
    </ng-template>
  </ng-select>
  <input
    [formControl]="entryControl"
    (ngModelChange)="updatePhone($event)"
    (blur)="markAsDirty()"
    [(ngModel)]="phone"
    [disabled]="disabled"
    class="form-control icon icon-phone"
    type="text"
  />
</div>
