import {UpdateContactInformationInput} from './../models/users.model';
import {Injectable} from '@angular/core';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthService,} from '@abacritt/angularx-social-login';
import {BehaviorSubject, catchError, map, Observable, of, switchMap, tap,} from 'rxjs';
import {Apollo, gql} from 'apollo-angular';

export interface User {
  pictureUrl?: string;
  permission: string;
  id: string;
  email: string;
  password?: string;
  fullName: string;
  name: string;
  checked: boolean;
  zipCode?: string;
  taxID?: number;
  city?: string;
  streetAddress?: string;
  country?: string;
  phoneNumber?: string;
  notify?: boolean;
}

type AuthToken = { accessToken: string; experiesIn: number; refreshToken: string };

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<User | null> = new Observable<User | null>();
  token$ = new BehaviorSubject<AuthToken | null>(null);

  constructor(
    private socialAuthService: SocialAuthService,
    private apollo: Apollo
  ) {
    if (localStorage.getItem('token')) {
      try {
        const token = JSON.parse(localStorage.getItem('token') ?? '{}');
        this.token$.next({
          accessToken: token?.accessToken,
          experiesIn: token?.experiesIn,
          refreshToken: token?.refreshToken
        });
      } catch (e) {
        console.log({e});
      }

    }
    this.user$ = this.token$.pipe(
      switchMap((token) => {
        if (token) {
          return this.user();
        }
        return of(null);
      })
    );
    this.token$.subscribe((token) => localStorage.setItem('token', JSON.stringify(token ?? {})));

    this.socialAuthService.authState
      .pipe(
        switchMap((socialUser) => {
          if (!socialUser) {
            return of(null);
          }

          switch (socialUser.provider) {
            case FacebookLoginProvider.PROVIDER_ID:
              return this.signUpWithFacebok(socialUser.authToken);
            case GoogleLoginProvider.PROVIDER_ID:
              return this.signUpWithGoogle(socialUser.idToken);
            default:
              return of(null);
          }
        })
      )
      .subscribe((authToken) => {
        if (!authToken) {
          return;
        }
        this.token$.next(authToken);
      });
  }

  signOut(): void {
    this.token$.next(null);
    // this.
  }

  signUpWithSystem(data: User): Observable<AuthToken | null> {
    const SIGNUP_WITH_SYSTEM = gql`
      mutation signUp($signUpInput: SignUpInput!) {
        signUp(SignUpInput: $signUpInput) {
          accessToken
          checked
        }
      }
    `;
    return this.apollo
      .mutate<{ signUp: AuthToken }>({
        mutation: SIGNUP_WITH_SYSTEM,
        variables: {
          signUpInput: {
            withSystem: {
              email: data.email,
              password: data.password,
              fullName: data.fullName,
              zipCode: Number.parseInt(`${data.zipCode}`, 10),
              taxID: Number.parseInt(`${data.taxID}`, 10),
              city: data.city,
              streetAddress: data.streetAddress,
              country: data.country,
              phoneNumber: data.phoneNumber,
            },
            ip: '127.0.0.1',
            acceptedTerms: true,
          },
        },
      })
      .pipe(
        map((response) => {
          console.log(response);
          return response.data?.signUp ?? null;
        }),
        tap((token) => {
          if (token) {
            console.log(token)
            this.token$.next(token);
          }
        })
      );
  }

  loginWithSystem(payload: { password: string; email: string }): Observable<AuthToken | null> {
    const SIGN_IN = gql`
      mutation singIn($signInInput: SignInInput!) {
        singIn(SignInInput: $signInInput) {
          accessToken
          refreshToken
          experiesIn
        }
      }
    `;
    return this.apollo
      .mutate<{ singIn: AuthToken }>({
        mutation: SIGN_IN,
        variables: {
          signInInput: {
            emailAndPassword: {
              email: payload.email,
              password: payload.password,
            },
            ip: '127.0.0.1',
          },
        },
      })
      .pipe(
        catchError((err) => of(null)),
        map((result) => {
          const authToken = result?.data?.singIn;
          if (!authToken) {
            return null;
          }
          this.token$.next(authToken);
          return authToken;
        })
      );
  }

  updateProfile(user: UpdateContactInformationInput): Observable<boolean> {
    const UPDATE_USER = gql`
      mutation UpdateContactInformation(
        $updateContactInformation: UpdateContactInformation!
      ) {
        updateContactInformation(
          UpdateContactInformation: $updateContactInformation
        )
      }
    `;
    return this.apollo
      .mutate<{ UpdateUser: boolean }>({
        mutation: UPDATE_USER,
        variables: {
          updateContactInformation: {
            name: user.name,
            zipCode: Number.parseInt(`${user.zipCode ?? ''}`, 10),
            taxID: Number.parseInt(`${user.taxID}`, 10),
            city: user.city,
            streetAddress: user.streetAddress,
            country: user.country,
            phoneNumber: user.phoneNumber,
            notify: user.notify
          },
        },
      })
      .pipe(map((response) => !!response.data?.UpdateUser));
  }

  public me(): Observable<User | null> {
    const ME_QUERY = gql`
      query {
        me {
          id
          email
          name
          name
          checked
          zipCode
          taxID
          city
          streetAddress
          country
          phoneNumber
          createdAt
          updatedAt
          permission
          pictureUrl
          notify
        }
      }
    `;
    return this.apollo
      .query<{ me: User }>({
        query: ME_QUERY,
      })
      .pipe(
        catchError((err) => {
          return of(null);
        }),
        map((data) => {
          if (!data) {
            return null;
          }
          return data.data.me;
        })
      );
  }

  // tslint:disable-next-line:typedef
  private signUpWithFacebok(authToken: string): Observable<AuthToken | null> {
    const SIGNUP_WITH_FACEBOOK = gql`
      mutation SignUp($token: String!) {
        signUp(
          SignUpInput: {
            withFacebook: { token: $token }
            ip: "127.0.0.1"
            acceptedTerms: true
          }
        ) {
          accessToken
          refreshToken
          experiesIn
        }
      }
    `;
    return this.apollo
      .mutate<{ signUp: AuthToken }>({
        mutation: SIGNUP_WITH_FACEBOOK,
        variables: {
          token: authToken,
        },
      })
      .pipe(map((response) => response.data?.signUp ?? null));
  }

  // tslint:disable-next-line:typedef
  private signUpWithGoogle(authToken: string): Observable<AuthToken | null> {
    const SIGNUP_WITH_GOOGLE = gql`
      mutation SignUp($token: String!) {
        signUp(
          SignUpInput: {
            withGoogle: { token: $token }
            ip: "127.0.0.1"
            acceptedTerms: true
          }
        ) {
          accessToken
          refreshToken
          experiesIn
        }
      }
    `;
    return this.apollo
      .mutate<{ signUp: AuthToken }>({
        mutation: SIGNUP_WITH_GOOGLE,
        variables: {
          token: authToken,
        },
      })
      .pipe(map((response) => response.data?.signUp ?? null));
  }

  private user(): Observable<User | null> {
    const ME_QUERY = gql`
      query {
        me {
          id
          email
          name
          checked
          permission
        }
      }
    `;
    return this.apollo
      .query<{ me: User }>({
        query: ME_QUERY,
      })
      .pipe(
        catchError((err) => {
          return of(null);
        }),
        map((data) => {
          if (!data) {
            return null;
          }
          return data.data.me;
        })
      );
  }

  forgotPassword(email: string): Observable<string> {
    const FORGOT_PASSWORD = gql`
      mutation ForgotPassword($email: String!) {
        forgotPassword(ForgotPasswordInput: { email: $email })
      }
    `;
    return this.apollo
      .mutate<{ forgotPassword: string }>({
        mutation: FORGOT_PASSWORD,
        variables: {
          email,
        },
      })
      .pipe(map((response) => response.data?.forgotPassword || ''));
  }

  resetPassword(token: string, newPassword: string): Observable<string> {
    const RESET_PASSWORD = gql`
      mutation ResetPassword($token: String!, $newPassword: String!) {
        resetPassword(
          ResetPasswordInput: { token: $token, newPassword: $newPassword }
        )
      }
    `;
    return this.apollo
      .mutate<{ resetPassword: string }>({
        mutation: RESET_PASSWORD,
        variables: {
          token,
          newPassword,
        },
      })
      .pipe(map((response) => response.data?.resetPassword || ''));
  }

  refreshToken(refreshTokenInput: {
    refreshToken: string;
  }): Observable<string | null> {
    const REFRESH_TOKEN = gql`
      mutation refreshToken(
        $refreshTokenInput: RefreshTokenInput!
      ) {
        refreshToken(RefreshTokenInput: $refreshTokenInput)
        {
          token
        }
      }
    `;
    return this.apollo
      .mutate<{ refreshToken: { token: string } }>({
        mutation: REFRESH_TOKEN,
        variables: {
          refreshTokenInput,
        },
      })
      .pipe(
        map((result) => {
          if (!result || !result.data) {
            return null;
          }

          return result.data.refreshToken.token;
        })
      );
  }

  setToken(newToken: string): void {
    this.token$.next({
      refreshToken: this.token$.value?.refreshToken ?? '',
      experiesIn: this.token$.value?.experiesIn ?? 0,
      accessToken: newToken,
    });

  }
}
