export type Extras = {
  type?: string;
  image?: File;
  link?: string;
  briefing?: string;
  images?: {
    id: string;
    pictureUrl: string;
  }[];
};

export type OrderType = {
  owner?: {
    id: string;
    name: string;
    email:string;
    phoneNumber:string
  };
  priceQuote?: File | string|null;
  ngbDeadline?: { month: number; year: number; day: number };
  deadLine?: Date;
  invoice?: File | string|null;
  id?: string;
  status?: string;
  visible?:boolean;
  delivery?:string,
  service: string;
  quantityItemShipped: number;
  quantityPhotosPerProduct: number;
  typeOfPhotos: string[];
  typeOfProducts: string[];
  imageDimension: string;
  colorBackgroundAndShadow: string[];
  extras: Extras;
  createdAt?: Date | null;
  updatedAt?: Date | null;
  log?: string
};

export type AllOrders = {
  orders: OrderType[];
  currentPage: number;
  totalPages: number;
}


export type OrderInputType = {
  id?: string;
  status?: string;
  service: string;
  quantityItemShipped: number;
  quantityPhotosPerProduct: number;
  typeOfPhotos: string[];
  typeOfProducts: string[];
  imageDimension: string;
  colorBackgroundAndShadow: string[];
  extras: {
    type?: string;
    image?: File;
    link?: string;
    briefing?: string;
    images?: File[];
  };
  createdAt?: Date | null;
  updatedAt?: Date | null;
};

export type CreateOrderByAdminInput ={
  ownerID?:string;
  createNewUser?:{
    fullName:string;
    email:string;
    phoneNumber:string
  }
  studio: 'Jerusalem'
  service: string;
  quantityItemShipped: number;
  quantityPhotosPerProduct: number;
  typeOfPhotos: string[];
  typeOfProducts: string[];
  imageDimension: string;
  colorBackgroundAndShadow: string[];
  extras: {
    type?: string;
    image?: File;
    link?: string;
    briefing?: string;
    images?: File[];
  };
  createdAt?: Date | null;
  updatedAt?: Date | null;

}
export type UpdatedOrderInput={
 orderID:string;
  studio: 'Jerusalem'
  status:string;
  service: string;
  quantityItemShipped: number;
  quantityPhotosPerProduct: number;
  typeOfPhotos: string[];
  typeOfProducts: string[];
  imageDimension: string;
  colorBackgroundAndShadow: string[];
  extras: {
    type?: string;
    link?: string;
    briefing?: string;
    image?: File[];
  };
  imagesIdtoRemoved:string[];
}

export type DuplicateOrderInput ={
  orderID:string;
  service: string;
  typeOfPhotos: string[];
  imageDimension: string;
  quantityItemShipped: number;
  quantityPhotosPerProduct: number;
  colorBackgroundAndShadow: string[];
  typeOfProducts: string[];
  extras: {
    type?: string;
    link?: string;
    briefing?: string;
    addImages?: File[];
    removeImages:string[];
  };

}
