<header class="bg-white sticky-top">
  <nav class="navbar">
    <div class="container">
      <div class="d-flex align-items-center">
        <a routerLink="/" class="navbar-brand">
          <img src="assets/images/logo-eshoots.png" class="d-inline-block align-text-middle" />
        </a>

        <ul class="navbar-social d-none d-lg-flex me-auto">
          <li class="me-2">
            <a href="https://www.instagram.com/eshoots.studio/" target="_blank" class="icon-instagram"><img
                src="assets/images/icons/instagram.svg" /></a>
          </li>
          <li class="me-2">
            <a href="https://www.facebook.com/eshoots.studio/" target="_blank" class="icon-facebook"><img
                src="assets/images/icons/facebook.svg" /></a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCmHaY8kSGsDtobgyh9tdfNg/featured" target="_blank"
              class="icon-youtube"><img src="assets/images/icons/youtube.svg" /></a>
          </li>
        </ul>
      </div>

      <ul class="navbar-nav d-none d-md-none d-lg-flex justify-content-md-end align-items-md-center">
        <li class="nav-item">
          <a class="btn btn-lg btn-black ps-3 pe-3"
            [routerLink]="((user$ | async)?.permission === 'admin' || (user$ | async)?.permission === 'studio') ? '/admin/order/form' : '/order'">Book
            Now</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-black" routerLink="/how-it-works">How it Works</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-black" href="https://calendly.com/eshoots" target="_blank">Rent Studio</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-black" routerLink="/contact">Contact</a>
        </li>
      </ul>

      <a routerLink="auth/login" class="btn btn-outline-black d-none d-md-flex" *ngIf="(user$|async) === null">Login</a>

      <div ngbDropdown class="d-none d-md-none d-lg-flex btn-profile" *ngIf="(user$ | async) as user">
        <button type="button" class="btn btn-lg btn-outline-black ps-3 pe-3" ngbDropdownToggle>
          {{user.name || 'Profile'}}
        </button>

        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end text-end" *ngIf="user.permission === 'client'">
          <a ngbDropdownItem routerLink="/my-profile" class="dropdown-item">My Profile</a>
          <a ngbDropdownItem routerLink="/my-orders" class="dropdown-item">My Orders</a>
          <a ngbDropdownItem href="javascript:void(0)" (click)="signOut()" class="dropdown-item">Logout</a>
        </div>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end text-end" *ngIf="user.permission === 'admin'">
          <a ngbDropdownItem routerLink="/admin/order/list" class="dropdown-item">In Studio</a>
          <a ngbDropdownItem routerLink="/admin/user/list" class="dropdown-item">User Adm</a>
          <a ngbDropdownItem routerLink="/admin/email/edit" class="dropdown-item">E-mails</a>

          <a ngbDropdownItem routerLink="/admin/client/list" class="dropdown-item">Clients</a>
          <a ngbDropdownItem (click)="signOut()" class="dropdown-item">Exit</a>
        </div>

        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end text-end" *ngIf="user.permission === 'studio'">
          <a ngbDropdownItem routerLink="/admin/order/list" class="dropdown-item">In Studio</a>
          <a ngbDropdownItem routerLink="/admin/client/list" class="dropdown-item">Clients</a>
          <a ngbDropdownItem (click)="signOut()" class="dropdown-item">Exit</a>
        </div>
      </div>

      <div ngbDropdown class="d-flex d-md-flex d-lg-none d-xl-none hamburguer-menu">
        <button ngbDropdownToggle type="button">
          <span></span>
        </button>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end text-end">
          <a ngbDropdownItem
            [routerLink]="((user$ | async)?.permission === 'admin' || (user$ | async)?.permission === 'studio') ? '/admin/order/form' : '/order'"
            class=" bg-black text-white">Book Now</a>
          <a ngbDropdownItem routerLink="/how-it-works">How it Works</a>
          <a ngbDropdownItem href="https://calendly.com/eshoots" target="_blank">Rent Studio</a>
          <a ngbDropdownItem routerLink="/contact">Contact</a>
          <div class="dropdown-divider"></div>
          <a ngbDropdownItem routerLink="auth/login" *ngIf="(user$ | async) === null">Login</a>
          <ng-container *ngIf="(user$ | async) as user">
            <ng-container *ngIf="user.permission === 'client'">
              <a ngbDropdownItem routerLink="/my-profile">My Profile</a>
              <a ngbDropdownItem routerLink="/my-orders">My Orders</a>
              <a ngbDropdownItem (click)="signOut()">Logout</a>
            </ng-container>

            <ng-container *ngIf="user.permission === 'admin'">
              <a ngbDropdownItem routerLink="/admin/order/list" class="dropdown-item">In Studio</a>
              <a ngbDropdownItem routerLink="/admin/user/list" class="dropdown-item">User Adm</a>
              <a ngbDropdownItem routerLink="/admin/email/edit" class="dropdown-item">E-mails</a>
              <a ngbDropdownItem routerLink="/admin/client/list" class="dropdown-item">Clients</a>
              <a ngbDropdownItem (click)="signOut()">Logout</a>
            </ng-container>

            <ng-container *ngIf="user.permission === 'studio'">
              <a ngbDropdownItem routerLink="/admin/order/list" class="dropdown-item">In Studio</a>
              <a ngbDropdownItem routerLink="/admin/client/list" class="dropdown-item">Clients</a>
              <a ngbDropdownItem (click)="signOut()">Logout</a>
            </ng-container>

          </ng-container>
          <div class="dropdown-divider"></div>
          <ul class="navbar-social">
            <li class="me-2">
              <a href="https://www.instagram.com/eshoots.studio/" target="_blank"><img
                  src="assets/images/icons/instagram.svg" /></a>
            </li>
            <li class="me-2">
              <a href="https://www.facebook.com/eshoots.studio/" target="_blank"><img
                  src="assets/images/icons/facebook.svg" /></a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCmHaY8kSGsDtobgyh9tdfNg/featured" target="_blank"><img
                  src="assets/images/icons/youtube.svg" /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</header>
