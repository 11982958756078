import {
  Component,
  forwardRef,
  inject,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALUE_ACCESSOR,
  NgControl,
  Validators,
} from '@angular/forms';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';

import { FormControl } from '@angular/forms';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Component({
  selector: 'app-input-tel',
  templateUrl: './input-tel.component.html',
  styles: [
    `
      ng-select {
        width: 160px;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTelComponent),
      multi: true,
    },
  ],
})
export class InputTelComponent implements OnInit, ControlValueAccessor {
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly toastr: ToastrService
  ) {
    this.formBuilder.group({
      phoneNumber: ['', Validators.required],
    });
  }

  @Input() entryControl: FormControl;
  @Input() countryControl: FormControl;

  apiService = inject(ApiService);
  disabled = false;
  value = '';
  countryCode: string;
  phone: string;
  countries$ = this.apiService.getCountries();

  ngOnInit(): void {
    this.countryCode = '+972';
  }

  writeValue(value: string): void {
    if (!value) {
      this.value = '';
      this.countryCode = '+972';
      this.phone = '';
      return;
    }
    try {
      const phoneNumber = phoneNumberUtil.parse(value);
      this.countryCode = `+${phoneNumber.getCountryCode()}`;

      if (!phoneNumberUtil.isValidNumber(phoneNumber)) return;

      this.phone = phoneNumberUtil.format(
        phoneNumber,
        PhoneNumberFormat.NATIONAL
      );
      this.value = value;
    } catch (error) {
      this.countryCode = '+972';
      this.phone = '';
      this.value = '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  updateCountryCode($event: any): void {
    this.value = `${$event}${this.phone}`;
    this.onChangeCallback(this.value);
    this.onTouchedCallback(this.value);
  }

  updatePhone($event: any): void {
    this.value = `${this.countryCode}${$event}`;
    this.value = phoneNumberUtil.format(
      phoneNumberUtil.parse(this.value),
      PhoneNumberFormat.NATIONAL
    );
    this.value = `${this.countryCode} ${this.value}`;
    this.onChangeCallback(this.value);
    this.onTouchedCallback(this.value);
  }

  private onTouchedCallback = (v: any) => {};
  private onChangeCallback = (v: any) => {};

  markAsDirty() {
    this.onTouchedCallback(this.value);
  }
}
