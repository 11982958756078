import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {DefaultOption, ImageOption} from '../models/options';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  getStudiosOptions(): Observable<ImageOption[]> {
    return of([
      {
        imgSrc: 'assets/images/photos-360.png',
        title: 'Jerusalem',
        text: '',
      },
      {
        imgSrc: 'assets/images/photos-360.png',
        title: 'Tel Aviv/Holon',
        text: '',
      },
    ]);
  }

  getServicesOptions(): Observable<ImageOption[]> {
    return of([
      {
        imgSrc: 'assets/images/24-hour-delivery.jpg',
        title: '24H Products Delivery',
        text: 'Send your products, and get your images in 24hours',
      },
      {
        imgSrc: 'assets/images/in-studio.jpg',
        title: 'In Studio',
        text: 'Book a photoshoot day and be present in studio',
      },
      {
        imgSrc: 'assets/images/virtual-shoot.jpg',
        title: 'Virtual Shoot',
        text: 'Book a photoshoot day and be virtualy present',
      },
    ]);
  }

  getPhotosOptions(): Observable<ImageOption[]> {
    return of([
      {
        imgSrc: 'assets/images/1-ghost-manequim-min.jpg',
        title: 'Ghost Manequim',
        text: '',
      },
      {
        imgSrc: 'assets/images/2-layflat-min.jpg',
        title: 'Lay Flat',
        text: '',
      },
      {
        imgSrc: 'assets/images/3-product-ecom shot-min.jpg',
        title: 'Product eCom Shoot',
        text: '',
      },
      {
        imgSrc: 'assets/images/4-model-photo-min.jpg',
        title: 'Model Photoshoot',
        text: '',
      },
      {
        imgSrc: 'assets/images/5-360-product-video-min.gif',
        title: '360 Video',
        text: '',
      },
      {
        imgSrc: 'assets/images/6-lifestyle-min.jpg',
        title: 'LifeStyle',
        text: '',
      },
      {
        imgSrc: 'assets/images/7-shoes-bags-min.jpg',
        title: 'Shoes, Bags',
        text: '',
      },
      {
        imgSrc: 'assets/images/8-group-photo-min.jpg',
        title: 'Group Photos',
        text: '',
      },
    ]);
  }

  getProductOptions(): Observable<DefaultOption[]> {
    return of([
      {title: 'Cosmetic'},
      {title: 'food & beverage'},
      {title: 'Home Products'},
      {title: 'Shirts'},
      {title: 'Acessories'},
      {title: 'Dresses'},
      {title: 'Skirts'},
      {title: 'Socks'},
      {title: 'Sports Wear'},
      {title: 'Pants'},
      {title: 'Baby Clothers'},
      {title: 'Shoes'},
      {title: 'Other'},
    ]);
  }

  getDimensionOptions(): Observable<string[]> {
    return of(['3:4', '1:1', '2:3', 'Other']);
  }

  getBackgroundOptions(): Observable<ImageOption[]> {
    return of([
      {
        imgSrc: 'assets/images/transparent-min.jpg',
        title: 'Transparent',
        text: '',
      },
      {
        imgSrc: 'assets/images/white-min.jpg',
        title: 'White',
        text: '',
      },
      {
        imgSrc: 'assets/images/light-gray-min.jpg',
        title: 'Light Grey',
        text: '',
      },
      {
        imgSrc: 'assets/images/natural-shadow-min.jpg',
        title: 'Natural Shadow',
        text: '',
      },
      {
        imgSrc: 'assets/images/harsh-shadow-min.jpg',
        title: 'Harsh Shadow',
        text: '',
      },
      {
        imgSrc: '',
        title: 'Other',
        text: '',
        description: true,
      }
    ]);
  }

  getExtrasOptions(): Observable<ImageOption[]> {
    return of([
      {
        imgSrc: 'assets/images/book-model-min.jpg',
        title: 'Book a Model',
        text: '',
      },
      {
        imgSrc: 'assets/images/stylist-min.jpg',
        title: 'Art Prop Styling',
        text: '',
      },
      {
        imgSrc: 'assets/images/book-makeup-min.jpg',
        title: 'Hair Stylist, Make up Artist',
        text: '',
      }
    ]);
  }

  getCountries(): Observable<{ name: string, alpha_2: string, code: string, code_number: number }[]> {
    return of([
      {
        name: 'Afghanistan',
        alpha_2: 'AF',
        code: '+93',
        code_number: 93
      },
      {
        name: 'Åland Islands',
        alpha_2: 'AX',
        code: '+358',
        code_number: 358
      },
      {
        name: 'Albania',
        alpha_2: 'AL',
        code: '+355',
        code_number: 355
      },
      {
        name: 'Algeria',
        alpha_2: 'DZ',
        code: '+213',
        code_number: 213
      },
      {
        name: 'American Samoa',
        alpha_2: 'AS',
        code: '+1684',
        code_number: 1684
      },
      {
        name: 'Andorra',
        alpha_2: 'AD',
        code: '+376',
        code_number: 376
      },
      {
        name: 'Angola',
        alpha_2: 'AO',
        code: '+244',
        code_number: 244
      },
      {
        name: 'Anguilla',
        alpha_2: 'AI',
        code: '+1264',
        code_number: 1264
      },
      {
        name: 'Antarctica',
        alpha_2: 'AQ',
        code: '+672',
        code_number: 672
      },
      {
        name: 'Antigua and Barbuda',
        alpha_2: 'AG',
        code: '+1268',
        code_number: 1268
      },
      {
        name: 'Argentina',
        alpha_2: 'AR',
        code: '+54',
        code_number: 54
      },
      {
        name: 'Armenia',
        alpha_2: 'AM',
        code: '+374',
        code_number: 374
      },
      {
        name: 'Aruba',
        alpha_2: 'AW',
        code: '+297',
        code_number: 297
      },
      {
        name: 'Australia',
        alpha_2: 'AU',
        code: '+61',
        code_number: 61
      },
      {
        name: 'Austria',
        alpha_2: 'AT',
        code: '+43',
        code_number: 43
      },
      {
        name: 'Azerbaijan',
        alpha_2: 'AZ',
        code: '+994',
        code_number: 994
      },
      {
        name: 'Bahamas',
        alpha_2: 'BS',
        code: '+1242',
        code_number: 1242
      },
      {
        name: 'Bahrain',
        alpha_2: 'BH',
        code: '+973',
        code_number: 973
      },
      {
        name: 'Bangladesh',
        alpha_2: 'BD',
        code: '+880',
        code_number: 880
      },
      {
        name: 'Barbados',
        alpha_2: 'BB',
        code: '+1246',
        code_number: 1246
      },
      {
        name: 'Belarus',
        alpha_2: 'BY',
        code: '+375',
        code_number: 375
      },
      {
        name: 'Belgium',
        alpha_2: 'BE',
        code: '+32',
        code_number: 32
      },
      {
        name: 'Belize',
        alpha_2: 'BZ',
        code: '+501',
        code_number: 501
      },
      {
        name: 'Benin',
        alpha_2: 'BJ',
        code: '+229',
        code_number: 229
      },
      {
        name: 'Bermuda',
        alpha_2: 'BM',
        code: '+1441',
        code_number: 1441
      },
      {
        name: 'Bhutan',
        alpha_2: 'BT',
        code: '+975',
        code_number: 975
      },
      {
        name: 'Bolivia (Plurinational State of)',
        alpha_2: 'BO',
        code: '+591',
        code_number: 591
      },
      {
        name: 'Bonaire Sint Eustatius and Saba',
        alpha_2: 'BQ',
        code: '+599',
        code_number: 599
      },
      {
        name: 'Bosnia and Herzegovina',
        alpha_2: 'BA',
        code: '+387',
        code_number: 387
      },
      {
        name: 'Botswana',
        alpha_2: 'BW',
        code: '+267',
        code_number: 267
      },
      {
        name: 'Bouvet Island',
        alpha_2: 'BV',
        code: '+47',
        code_number: 47
      },
      {
        name: 'Brazil',
        alpha_2: 'BR',
        code: '+55',
        code_number: 55
      },
      {
        name: 'British Indian Ocean Territory',
        alpha_2: 'IO',
        code: '+246',
        code_number: 246
      },
      {
        name: 'Brunei Darussalam',
        alpha_2: 'BN',
        code: '+673',
        code_number: 673
      },
      {
        name: 'Bulgaria',
        alpha_2: 'BG',
        code: '+359',
        code_number: 359
      },
      {
        name: 'Burkina Faso',
        alpha_2: 'BF',
        code: '+226',
        code_number: 226
      },
      {
        name: 'Burundi',
        alpha_2: 'BI',
        code: '+257',
        code_number: 257
      },
      {
        name: 'Cabo Verde',
        alpha_2: 'CV',
        code: '+238',
        code_number: 238
      },
      {
        name: 'Cambodia',
        alpha_2: 'KH',
        code: '+855',
        code_number: 855
      },
      {
        name: 'Cameroon',
        alpha_2: 'CM',
        code: '+237',
        code_number: 237
      },
      {
        name: 'Canada',
        alpha_2: 'CA',
        code: '+1',
        code_number: 1
      },
      {
        name: 'Cayman Islands',
        alpha_2: 'KY',
        code: '+1345',
        code_number: 1345
      },
      {
        name: 'Central African Republic',
        alpha_2: 'CF',
        code: '+236',
        code_number: 236
      },
      {
        name: 'Chad',
        alpha_2: 'TD',
        code: '+235',
        code_number: 235
      },
      {
        name: 'Chile',
        alpha_2: 'CL',
        code: '+56',
        code_number: 56
      },
      {
        name: 'China',
        alpha_2: 'CN',
        code: '+86',
        code_number: 86
      },
      {
        name: 'Christmas Island',
        alpha_2: 'CX',
        code: '+61',
        code_number: 61
      },
      {
        name: 'Cocos (Keeling) Islands',
        alpha_2: 'CC',
        code: '+61',
        code_number: 61
      },
      {
        name: 'Colombia',
        alpha_2: 'CO',
        code: '+57',
        code_number: 57
      },
      {
        name: 'Comoros',
        alpha_2: 'KM',
        code: '+269',
        code_number: 269
      },
      {
        name: 'Congo',
        alpha_2: 'CG',
        code: '+242',
        code_number: 242
      },
      {
        name: 'Congo',
        alpha_2: 'CD',
        code: '+243',
        code_number: 243
      },
      {
        name: 'Cook Islands',
        alpha_2: 'CK',
        code: '+682',
        code_number: 682
      },
      {
        name: 'Costa Rica',
        alpha_2: 'CR',
        code: '+506',
        code_number: 506
      },
      {
        name: 'Côte d\'Ivoire',
        alpha_2: 'CI',
        code: '+225',
        code_number: 225
      },
      {
        name: 'Croatia',
        alpha_2: 'HR',
        code: '+385',
        code_number: 385
      },
      {
        name: 'Cuba',
        alpha_2: 'CU',
        code: '+53',
        code_number: 53
      },
      {
        name: 'Curaçao',
        alpha_2: 'CW',
        code: '+599',
        code_number: 599
      },
      {
        name: 'Cyprus',
        alpha_2: 'CY',
        code: '+357',
        code_number: 357
      },
      {
        name: 'Czechia',
        alpha_2: 'CZ',
        code: '+420',
        code_number: 420
      },
      {
        name: 'Denmark',
        alpha_2: 'DK',
        code: '+45',
        code_number: 45
      },
      {
        name: 'Djibouti',
        alpha_2: 'DJ',
        code: '+253',
        code_number: 253
      },
      {
        name: 'Dominica',
        alpha_2: 'DM',
        code: '+1767',
        code_number: 1767
      },
      {
        name: 'Dominican Republic',
        alpha_2: 'DO',
        code: '+1849',
        code_number: 1849
      },
      {
        name: 'Ecuador',
        alpha_2: 'EC',
        code: '+593',
        code_number: 593
      },
      {
        name: 'Egypt',
        alpha_2: 'EG',
        code: '+20',
        code_number: 20
      },
      {
        name: 'El Salvador',
        alpha_2: 'SV',
        code: '+503',
        code_number: 503
      },
      {
        name: 'Equatorial Guinea',
        alpha_2: 'GQ',
        code: '+240',
        code_number: 240
      },
      {
        name: 'Eritrea',
        alpha_2: 'ER',
        code: '+291',
        code_number: 291
      },
      {
        name: 'Estonia',
        alpha_2: 'EE',
        code: '+372',
        code_number: 372
      },
      {
        name: 'Eswatini',
        alpha_2: 'SZ',
        code: '+268',
        code_number: 268
      },
      {
        name: 'Ethiopia',
        alpha_2: 'ET',
        code: '+251',
        code_number: 251
      },
      {
        name: 'Falkland Islands (Malvinas)',
        alpha_2: 'FK',
        code: '+500',
        code_number: 500
      },
      {
        name: 'Faroe Islands',
        alpha_2: 'FO',
        code: '+298',
        code_number: 298
      },
      {
        name: 'Fiji',
        alpha_2: 'FJ',
        code: '+679',
        code_number: 679
      },
      {
        name: 'Finland',
        alpha_2: 'FI',
        code: '+358',
        code_number: 358
      },
      {
        name: 'France',
        alpha_2: 'FR',
        code: '+33',
        code_number: 33
      },
      {
        name: 'French Guiana',
        alpha_2: 'GF',
        code: '+594',
        code_number: 594
      },
      {
        name: 'French Polynesia',
        alpha_2: 'PF',
        code: '+689',
        code_number: 689
      },
      {
        name: 'French Southern Territories',
        alpha_2: 'TF',
        code: '+262',
        code_number: 262
      },
      {
        name: 'Gabon',
        alpha_2: 'GA',
        code: '+241',
        code_number: 241
      },
      {
        name: 'Gambia',
        alpha_2: 'GM',
        code: '+220',
        code_number: 220
      },
      {
        name: 'Georgia',
        alpha_2: 'GE',
        code: '+995',
        code_number: 995
      },
      {
        name: 'Germany',
        alpha_2: 'DE',
        code: '+49',
        code_number: 49
      },
      {
        name: 'Ghana',
        alpha_2: 'GH',
        code: '+233',
        code_number: 233
      },
      {
        name: 'Gibraltar',
        alpha_2: 'GI',
        code: '+350',
        code_number: 350
      },
      {
        name: 'Greece',
        alpha_2: 'GR',
        code: '+30',
        code_number: 30
      },
      {
        name: 'Greenland',
        alpha_2: 'GL',
        code: '+299',
        code_number: 299
      },
      {
        name: 'Grenada',
        alpha_2: 'GD',
        code: '+1473',
        code_number: 1473
      },
      {
        name: 'Guadeloupe',
        alpha_2: 'GP',
        code: '+590',
        code_number: 590
      },
      {
        name: 'Guam',
        alpha_2: 'GU',
        code: '+1671',
        code_number: 1671
      },
      {
        name: 'Guatemala',
        alpha_2: 'GT',
        code: '+502',
        code_number: 502
      },
      {
        name: 'Guernsey',
        alpha_2: 'GG',
        code: '+44',
        code_number: 44
      },
      {
        name: 'Guinea',
        alpha_2: 'GN',
        code: '+224',
        code_number: 224
      },
      {
        name: 'Guinea-Bissau',
        alpha_2: 'GW',
        code: '+245',
        code_number: 245
      },
      {
        name: 'Guyana',
        alpha_2: 'GY',
        code: '+592',
        code_number: 592
      },
      {
        name: 'Haiti',
        alpha_2: 'HT',
        code: '+509',
        code_number: 509
      },
      {
        name: 'Heard Island and McDonald Islands',
        alpha_2: 'HM',
        code: '+672',
        code_number: 672
      },
      {
        name: 'Holy See',
        alpha_2: 'VA',
        code: '+379',
        code_number: 379
      },
      {
        name: 'Honduras',
        alpha_2: 'HN',
        code: '+504',
        code_number: 504
      },
      {
        name: 'Hong Kong',
        alpha_2: 'HK',
        code: '+852',
        code_number: 852
      },
      {
        name: 'Hungary',
        alpha_2: 'HU',
        code: '+36',
        code_number: 36
      },
      {
        name: 'Iceland',
        alpha_2: 'IS',
        code: '+354',
        code_number: 354
      },
      {
        name: 'India',
        alpha_2: 'IN',
        code: '+91',
        code_number: 91
      },
      {
        name: 'Indonesia',
        alpha_2: 'ID',
        code: '+62',
        code_number: 62
      },
      {
        name: 'Iran (Islamic Republic of)',
        alpha_2: 'IR',
        code: '+98',
        code_number: 98
      },
      {
        name: 'Iraq',
        alpha_2: 'IQ',
        code: '+964',
        code_number: 964
      },
      {
        name: 'Ireland',
        alpha_2: 'IE',
        code: '+353',
        code_number: 353
      },
      {
        name: 'Isle of Man',
        alpha_2: 'IM',
        code: '+44',
        code_number: 44
      },
      {
        name: 'Israel',
        alpha_2: 'IL',
        code: '+972',
        code_number: 972
      },
      {
        name: 'Italy',
        alpha_2: 'IT',
        code: '+39',
        code_number: 39
      },
      {
        name: 'Jamaica',
        alpha_2: 'JM',
        code: '+1876',
        code_number: 1876
      },
      {
        name: 'Japan',
        alpha_2: 'JP',
        code: '+81',
        code_number: 81
      },
      {
        name: 'Jersey',
        alpha_2: 'JE',
        code: '+44',
        code_number: 44
      },
      {
        name: 'Jordan',
        alpha_2: 'JO',
        code: '+962',
        code_number: 962
      },
      {
        name: 'Kazakhstan',
        alpha_2: 'KZ',
        code: '+77',
        code_number: 77
      },
      {
        name: 'Kenya',
        alpha_2: 'KE',
        code: '+254',
        code_number: 254
      },
      {
        name: 'Kiribati',
        alpha_2: 'KI',
        code: '+686',
        code_number: 686
      },
      {
        name: 'Korea',
        alpha_2: 'KR',
        code: '+82',
        code_number: 82
      },
      {
        name: 'Korea (Democratic People\'s Republic of)',
        alpha_2: 'KP',
        code: '+850',
        code_number: 850
      },
      {
        name: 'Kuwait',
        alpha_2: 'KW',
        code: '+965',
        code_number: 965
      },
      {
        name: 'Kyrgyzstan',
        alpha_2: 'KG',
        code: '+996',
        code_number: 996
      },
      {
        name: 'Lao People\'s Democratic Republic',
        alpha_2: 'LA',
        code: '+856',
        code_number: 856
      },
      {
        name: 'Latvia',
        alpha_2: 'LV',
        code: '+371',
        code_number: 371
      },
      {
        name: 'Lebanon',
        alpha_2: 'LB',
        code: '+961',
        code_number: 961
      },
      {
        name: 'Lesotho',
        alpha_2: 'LS',
        code: '+266',
        code_number: 266
      },
      {
        name: 'Liberia',
        alpha_2: 'LR',
        code: '+231',
        code_number: 231
      },
      {
        name: 'Libya',
        alpha_2: 'LY',
        code: '+218',
        code_number: 218
      },
      {
        name: 'Liechtenstein',
        alpha_2: 'LI',
        code: '+423',
        code_number: 423
      },
      {
        name: 'Lithuania',
        alpha_2: 'LT',
        code: '+370',
        code_number: 370
      },
      {
        name: 'Luxembourg',
        alpha_2: 'LU',
        code: '+352',
        code_number: 352
      },
      {
        name: 'Macao',
        alpha_2: 'MO',
        code: '+853',
        code_number: 853
      },
      {
        name: 'Madagascar',
        alpha_2: 'MG',
        code: '+261',
        code_number: 261
      },
      {
        name: 'Malawi',
        alpha_2: 'MW',
        code: '+265',
        code_number: 265
      },
      {
        name: 'Malaysia',
        alpha_2: 'MY',
        code: '+60',
        code_number: 60
      },
      {
        name: 'Maldives',
        alpha_2: 'MV',
        code: '+960',
        code_number: 960
      },
      {
        name: 'Mali',
        alpha_2: 'ML',
        code: '+223',
        code_number: 223
      },
      {
        name: 'Malta',
        alpha_2: 'MT',
        code: '+356',
        code_number: 356
      },
      {
        name: 'Marshall Islands',
        alpha_2: 'MH',
        code: '+692',
        code_number: 692
      },
      {
        name: 'Martinique',
        alpha_2: 'MQ',
        code: '+596',
        code_number: 596
      },
      {
        name: 'Mauritania',
        alpha_2: 'MR',
        code: '+222',
        code_number: 222
      },
      {
        name: 'Mauritius',
        alpha_2: 'MU',
        code: '+230',
        code_number: 230
      },
      {
        name: 'Mayotte',
        alpha_2: 'YT',
        code: '+262',
        code_number: 262
      },
      {
        name: 'Mexico',
        alpha_2: 'MX',
        code: '+52',
        code_number: 52
      },
      {
        name: 'Micronesia (Federated States of)',
        alpha_2: 'FM',
        code: '+691',
        code_number: 691
      },
      {
        name: 'Moldova',
        alpha_2: 'MD',
        code: '+373',
        code_number: 373
      },
      {
        name: 'Monaco',
        alpha_2: 'MC',
        code: '+377',
        code_number: 377
      },
      {
        name: 'Mongolia',
        alpha_2: 'MN',
        code: '+976',
        code_number: 976
      },
      {
        name: 'Montenegro',
        alpha_2: 'ME',
        code: '+382',
        code_number: 382
      },
      {
        name: 'Montserrat',
        alpha_2: 'MS',
        code: '+1664',
        code_number: 1664
      },
      {
        name: 'Morocco',
        alpha_2: 'MA',
        code: '+212',
        code_number: 212
      },
      {
        name: 'Mozambique',
        alpha_2: 'MZ',
        code: '+258',
        code_number: 258
      },
      {
        name: 'Myanmar',
        alpha_2: 'MM',
        code: '+95',
        code_number: 95
      },
      {
        name: 'Namibia',
        alpha_2: 'NA',
        code: '+264',
        code_number: 264
      },
      {
        name: 'Nauru',
        alpha_2: 'NR',
        code: '+674',
        code_number: 674
      },
      {
        name: 'Nepal',
        alpha_2: 'NP',
        code: '+977',
        code_number: 977
      },
      {
        name: 'Netherlands',
        alpha_2: 'NL',
        code: '+31',
        code_number: 31
      },
      {
        name: 'New Caledonia',
        alpha_2: 'NC',
        code: '+687',
        code_number: 687
      },
      {
        name: 'New Zealand',
        alpha_2: 'NZ',
        code: '+64',
        code_number: 64
      },
      {
        name: 'Nicaragua',
        alpha_2: 'NI',
        code: '+505',
        code_number: 505
      },
      {
        name: 'Niger',
        alpha_2: 'NE',
        code: '+227',
        code_number: 227
      },
      {
        name: 'Nigeria',
        alpha_2: 'NG',
        code: '+234',
        code_number: 234
      },
      {
        name: 'Niue',
        alpha_2: 'NU',
        code: '+683',
        code_number: 683
      },
      {
        name: 'Norfolk Island',
        alpha_2: 'NF',
        code: '+672',
        code_number: 672
      },
      {
        name: 'North Macedonia',
        alpha_2: 'MK',
        code: '+389',
        code_number: 389
      },
      {
        name: 'Northern Mariana Islands',
        alpha_2: 'MP',
        code: '+1670',
        code_number: 1670
      },
      {
        name: 'Norway',
        alpha_2: 'NO',
        code: '+47',
        code_number: 47
      },
      {
        name: 'Oman',
        alpha_2: 'OM',
        code: '+968',
        code_number: 968
      },
      {
        name: 'Pakistan',
        alpha_2: 'PK',
        code: '+92',
        code_number: 92
      },
      {
        name: 'Palau',
        alpha_2: 'PW',
        code: '+680',
        code_number: 680
      },
      {
        name: 'Panama',
        alpha_2: 'PA',
        code: '+507',
        code_number: 507
      },
      {
        name: 'Papua New Guinea',
        alpha_2: 'PG',
        code: '+675',
        code_number: 675
      },
      {
        name: 'Paraguay',
        alpha_2: 'PY',
        code: '+595',
        code_number: 595
      },
      {
        name: 'Peru',
        alpha_2: 'PE',
        code: '+51',
        code_number: 51
      },
      {
        name: 'Philippines',
        alpha_2: 'PH',
        code: '+63',
        code_number: 63
      },
      {
        name: 'Pitcairn',
        alpha_2: 'PN',
        code: '+64',
        code_number: 64
      },
      {
        name: 'Poland',
        alpha_2: 'PL',
        code: '+48',
        code_number: 48
      },
      {
        name: 'Portugal',
        alpha_2: 'PT',
        code: '+351',
        code_number: 351
      },
      {
        name: 'Puerto Rico',
        alpha_2: 'PR',
        code: '+1939',
        code_number: 1939
      },
      {
        name: 'Qatar',
        alpha_2: 'QA',
        code: '+974',
        code_number: 974
      },
      {
        name: 'Réunion',
        alpha_2: 'RE',
        code: '+262',
        code_number: 262
      },
      {
        name: 'Romania',
        alpha_2: 'RO',
        code: '+40',
        code_number: 40
      },
      {
        name: 'Russian Federation',
        alpha_2: 'RU',
        code: '+7',
        code_number: 7
      },
      {
        name: 'Rwanda',
        alpha_2: 'RW',
        code: '+250',
        code_number: 250
      },
      {
        name: 'Saint Barthélemy',
        alpha_2: 'BL',
        code: '+590',
        code_number: 590
      },
      {
        name: 'Saint Helena',
        alpha_2: 'SH',
        code: '+290',
        code_number: 290
      },
      {
        name: 'Saint Kitts and Nevis',
        alpha_2: 'KN',
        code: '+1869',
        code_number: 1869
      },
      {
        name: 'Saint Lucia',
        alpha_2: 'LC',
        code: '+1758',
        code_number: 1758
      },
      {
        name: 'Saint Martin (French part)',
        alpha_2: 'MF',
        code: '+590',
        code_number: 590
      },
      {
        name: 'Saint Pierre and Miquelon',
        alpha_2: 'PM',
        code: '+508',
        code_number: 508
      },
      {
        name: 'Saint Vincent and the Grenadines',
        alpha_2: 'VC',
        code: '+1784',
        code_number: 1784
      },
      {
        name: 'Samoa',
        alpha_2: 'WS',
        code: '+685',
        code_number: 685
      },
      {
        name: 'San Marino',
        alpha_2: 'SM',
        code: '+378',
        code_number: 378
      },
      {
        name: 'Sao Tome and Principe',
        alpha_2: 'ST',
        code: '+239',
        code_number: 239
      },
      {
        name: 'Saudi Arabia',
        alpha_2: 'SA',
        code: '+966',
        code_number: 966
      },
      {
        name: 'Senegal',
        alpha_2: 'SN',
        code: '+221',
        code_number: 221
      },
      {
        name: 'Serbia',
        alpha_2: 'RS',
        code: '+381',
        code_number: 381
      },
      {
        name: 'Seychelles',
        alpha_2: 'SC',
        code: '+248',
        code_number: 248
      },
      {
        name: 'Sierra Leone',
        alpha_2: 'SL',
        code: '+232',
        code_number: 232
      },
      {
        name: 'Singapore',
        alpha_2: 'SG',
        code: '+65',
        code_number: 65
      },
      {
        name: 'Sint Maarten (Dutch part)',
        alpha_2: 'SX',
        code: '+599',
        code_number: 599
      },
      {
        name: 'Slovakia',
        alpha_2: 'SK',
        code: '+421',
        code_number: 421
      },
      {
        name: 'Slovenia',
        alpha_2: 'SI',
        code: '+386',
        code_number: 386
      },
      {
        name: 'Solomon Islands',
        alpha_2: 'SB',
        code: '+677',
        code_number: 677
      },
      {
        name: 'Somalia',
        alpha_2: 'SO',
        code: '+252',
        code_number: 252
      },
      {
        name: 'South Africa',
        alpha_2: 'ZA',
        code: '+27',
        code_number: 27
      },
      {
        name: 'South Georgia and the South Sandwich Islands',
        alpha_2: 'GS',
        code: '+500',
        code_number: 500
      },
      {
        name: 'South Sudan',
        alpha_2: 'SS',
        code: '+211',
        code_number: 211
      },
      {
        name: 'Spain',
        alpha_2: 'ES',
        code: '+34',
        code_number: 34
      },
      {
        name: 'Sri Lanka',
        alpha_2: 'LK',
        code: '+94',
        code_number: 94
      },
      {
        name: 'State of Palestine',
        alpha_2: 'PS',
        code: '+970',
        code_number: 970
      },
      {
        name: 'Sudan',
        alpha_2: 'SD',
        code: '+249',
        code_number: 249
      },
      {
        name: 'Suriname',
        alpha_2: 'SR',
        code: '+597',
        code_number: 597
      },
      {
        name: 'Svalbard and Jan Mayen',
        alpha_2: 'SJ',
        code: '+47',
        code_number: 47
      },
      {
        name: 'Sweden',
        alpha_2: 'SE',
        code: '+46',
        code_number: 46
      },
      {
        name: 'Switzerland',
        alpha_2: 'CH',
        code: '+41',
        code_number: 41
      },
      {
        name: 'Syrian Arab Republic',
        alpha_2: 'SY',
        code: '+963',
        code_number: 963
      },
      {
        name: 'Taiwan',
        alpha_2: 'TW',
        code: '+886',
        code_number: 886
      },
      {
        name: 'Tajikistan',
        alpha_2: 'TJ',
        code: '+992',
        code_number: 992
      },
      {
        name: 'Tanzania',
        alpha_2: 'TZ',
        code: '+255',
        code_number: 255
      },
      {
        name: 'Thailand',
        alpha_2: 'TH',
        code: '+66',
        code_number: 66
      },
      {
        name: 'Timor-Leste',
        alpha_2: 'TL',
        code: '+670',
        code_number: 670
      },
      {
        name: 'Togo',
        alpha_2: 'TG',
        code: '+228',
        code_number: 228
      },
      {
        name: 'Tokelau',
        alpha_2: 'TK',
        code: '+690',
        code_number: 690
      },
      {
        name: 'Tonga',
        alpha_2: 'TO',
        code: '+676',
        code_number: 676
      },
      {
        name: 'Trinidad and Tobago',
        alpha_2: 'TT',
        code: '+1868',
        code_number: 1868
      },
      {
        name: 'Tunisia',
        alpha_2: 'TN',
        code: '+216',
        code_number: 216
      },
      {
        name: 'Turkey',
        alpha_2: 'TR',
        code: '+90',
        code_number: 90
      },
      {
        name: 'Turkmenistan',
        alpha_2: 'TM',
        code: '+993',
        code_number: 993
      },
      {
        name: 'Turks and Caicos Islands',
        alpha_2: 'TC',
        code: '+1649',
        code_number: 1649
      },
      {
        name: 'Tuvalu',
        alpha_2: 'TV',
        code: '+688',
        code_number: 688
      },
      {
        name: 'Uganda',
        alpha_2: 'UG',
        code: '+256',
        code_number: 256
      },
      {
        name: 'Ukraine',
        alpha_2: 'UA',
        code: '+380',
        code_number: 380
      },
      {
        name: 'United Arab Emirates',
        alpha_2: 'AE',
        code: '+971',
        code_number: 971
      },
      {
        name: 'United Kingdom of Great Britain and Northern Ireland',
        alpha_2: 'GB',
        code: '+44',
        code_number: 44
      },
      {
        name: 'United States Minor Outlying Islands',
        alpha_2: 'UM',
        code: '+246',
        code_number: 246
      },
      {
        name: 'United States of America',
        alpha_2: 'US',
        code: '+1',
        code_number: 1
      },
      {
        name: 'Uruguay',
        alpha_2: 'UY',
        code: '+598',
        code_number: 598
      },
      {
        name: 'Uzbekistan',
        alpha_2: 'UZ',
        code: '+998',
        code_number: 998
      },
      {
        name: 'Vanuatu',
        alpha_2: 'VU',
        code: '+678',
        code_number: 678
      },
      {
        name: 'Venezuela (Bolivarian Republic of)',
        alpha_2: 'VE',
        code: '+58',
        code_number: 58
      },
      {
        name: 'Viet Nam',
        alpha_2: 'VN',
        code: '+84',
        code_number: 84
      },
      {
        name: 'Virgin Islands (British)',
        alpha_2: 'VG',
        code: '+1284',
        code_number: 1284
      },
      {
        name: 'Virgin Islands (U.S.)',
        alpha_2: 'VI',
        code: '+1340',
        code_number: 1340
      },
      {
        name: 'Wallis and Futuna',
        alpha_2: 'WF',
        code: '+681',
        code_number: 681
      },
      {
        name: 'Western Sahara',
        alpha_2: 'EH',
        code: '+212',
        code_number: 212
      },
      {
        name: 'Yemen',
        alpha_2: 'YE',
        code: '+967',
        code_number: 967
      },
      {
        name: 'Zambia',
        alpha_2: 'ZM',
        code: '+260',
        code_number: 260
      },
      {
        name: 'Zimbabwe',
        alpha_2: 'ZW',
        code: '+263',
        code_number: 263
      }
    ]);
  }
}
