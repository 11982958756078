import {inject, Pipe, PipeTransform} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Pipe({
  name: 'authenticatedUrl',
})
export class AuthenticatedUrlPipe implements PipeTransform {
  private httpClient = inject(HttpClient);
  private sanitizer = inject(DomSanitizer);

  transform(
    url: string,
  ): Observable<SafeResourceUrl | null> {
    if (!url) {
      return of(null);
    }
    return this.httpClient.get(url, {responseType: 'blob'}).pipe(
      map((e) =>
        this.sanitizer.bypassSecurityTrustResourceUrl(
          URL.createObjectURL(e)
        ))
    );
  }
}
