import {ToastrService} from 'ngx-toastr';
import {UserType} from 'src/app/models/users.model';
import {OrderType} from 'src/app/models/order.model';
import {catchError, concat, distinctUntilChanged, filter, Observable, of, Subject, switchMap, tap,} from 'rxjs';
import {ApiService} from './../../../services/api.service';
import {OrderService} from './../../../services/order.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {FileFormatValidator} from 'src/app/validators/file-format.validator';
import {FileSizeValidator} from 'src/app/validators/file-size.validator';
import {NgSelectComponent} from '@ng-select/ng-select';
import {Location} from '@angular/common';
import { PhoneNumberUtil } from 'google-libphonenumber';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss'],
})
export class OrderFormComponent implements OnInit {
  public edit = false;
  @ViewChild('name') name: NgSelectComponent;
  orderId: string | null = '';
  typeStudio = [
    {id: 1, name: 'Ghost Manequim'},
    {id: 2, name: 'Lay Flat'},
    {id: 3, name: 'Product eCom Shoot'},
    {id: 4, name: 'Model Photoshoot'},
    {id: 5, name: '360 Video'},
    {id: 6, name: 'LifeStyle'},
    {id: 7, name: 'Product Video'},
  ];
  typeProduct = [
    {id: 1, name: 'Cosmetic'},
    {id: 2, name: 'Food & Beverage'},
    {id: 3, name: 'Home Products'},
    {id: 4, name: 'Shirts'},
    {id: 5, name: 'Acessories'},
    {id: 6, name: 'Dresses'},
    {id: 7, name: 'Skirts'},
    {id: 8, name: 'Socks'},
    {id: 9, name: 'Sports Wear'},
    {id: 10, name: 'Pants'},
    {id: 11, name: 'Baby Clothers'},
    {id: 12, name: 'Shoes'},
  ];

  imageDimension = [
    {id: 1, name: '3:4'},
    {id: 2, name: '1:1'},
    {id: 3, name: '2:3'},
  ];
  typeBackground = [
    {id: 1, name: 'Transparent'},
    {id: 2, name: 'White'},
    {id: 3, name: 'Light Grey'},
    {id: 4, name: 'Natural Shadow'},
    {id: 5, name: 'Harsh Shadow'},
  ];
  extra = [
    {id: 1, name: 'Book a Model'},
    {id: 2, name: 'Art Prop Styling'},
    {id: 3, name: 'Hair Stylist, Make up Artist'},
  ];
  client$: Observable<UserType[]>;
  clientInput$ = new Subject<string>();
  clientLoading = false;

  fg: FormGroup;
  fgUser: FormGroup;

  pickServiceOptions$;
  photosOptions$;
  productOptions$;
  dimensionOptions$;
  backgroundOptions$;
  extrasOptions$;
  clientId: string | null;
  order: OrderType;
  imagesIdtoRemoved: { [key: string]: boolean } = {};
  canCancel = true;
  duplicate = false;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private orderService: OrderService,
    private apiService: ApiService,
    private userService: UserService,
    private router: Router,
    private toastrService: ToastrService,
    private location: Location
  ) {

    this.pickServiceOptions$ = this.apiService.getServicesOptions();
    this.photosOptions$ = this.apiService.getPhotosOptions();
    this.productOptions$ = this.apiService.getProductOptions();
    this.dimensionOptions$ = this.apiService.getDimensionOptions();
    this.backgroundOptions$ = this.apiService.getBackgroundOptions();
    this.extrasOptions$ = this.apiService.getExtrasOptions();

    this.fgUser = this.formBuilder.group({
      id: [null],
      name: ['', [Validators.required]],
      phone: ['', []],
      countryCode: [''],
      email: ['', [Validators.required, Validators.email]],
    });

    this.fg = this.formBuilder.group({
      pickService: ['', [Validators.required]],
      status: [],
      quantity: ['', [Validators.required]],
      numPhoto: ['', [Validators.required]],
      typeStudios: [[], [Validators.required]],
      typeProducts: [[], [Validators.required]],
      imageDimensions: [[], [Validators.required]],
      typeBackgrounds: [[], [Validators.required]],
      extras: [''],
      references: this.formBuilder.array([]),
      link: [],
      briefing: [],
    });
  }

  get referenceFile() {
    return this.fg.controls['references'] as FormArray;
  }

  ngOnInit(): void {
    this.loadClient();
    this.orderId = this.activatedRoute.snapshot.paramMap.get('order_id');
    this.clientId = this.activatedRoute.snapshot.paramMap.get('client_id');
    if (this.clientId) {
      const phoneUtil = PhoneNumberUtil.getInstance()
      
      this.userService
      .getUserById({id: this.clientId})
      .subscribe((client) => {
          const brutePhone = phoneUtil.parse(client.phoneNumber)
          const countryCode = brutePhone.getCountryCode()
          const phone = brutePhone.getNationalNumber()

          this.fgUser.patchValue({
            id: client.id,
            name: client.name,
            email: client.email,
            phone,
            countryCode
          });
        });
      this.fgUser.disable();
    }
    if (this.orderId) {
      this.fg.disable();
      this.fgUser.disable();
      this.orderService
        .getOrderById({id: this.orderId})
        .subscribe((order) => {
          this.verifyCancel(order);
          this.fg.patchValue({
            chooseStudio: 'Jerusalem',
            pickService: order.service,
            status: order.status,
            quantity: order.quantityItemShipped,
            numPhoto: order.quantityPhotosPerProduct,
            typeStudios: order.typeOfPhotos,
            typeProducts: order.typeOfProducts,
            imageDimensions: order.imageDimension,
            typeBackgrounds: order.colorBackgroundAndShadow,
            extras: order.extras.type,
            references: order.extras.images,
            briefing: order.extras.briefing,
            link: order.extras.link,
          });
          this.fgUser.patchValue({
            name: order.owner?.name,
            email: order.owner?.email,
            phone: order.owner?.phoneNumber,
          });
          this.order = order;
        });
    }
  }

  private loadClient() {
    this.client$ = concat(
      of([]),
      this.clientInput$.pipe(
        distinctUntilChanged(),
        filter((term) => !!term),
        tap(() => (this.clientLoading = true)),

        switchMap((term) =>
          this.userService.getsearchUsers({text: term}).pipe(
            catchError(() => of([])),
            tap(() => (this.clientLoading = false))
          )
        )
      )
    );
  }

  setClient(client: UserType) {
    const phoneUtil = PhoneNumberUtil.getInstance()
    const brutePhone = phoneUtil.parse(client.phoneNumber)
    const countryCode = brutePhone.getCountryCode()
    const nationalNumber = brutePhone.getNationalNumber()

    const phone = `+${countryCode}${nationalNumber}`

    if (client.id) {

      this.fgUser.patchValue({
        id: client.id,
        name: client.name,
        phone: nationalNumber,
        countryCode: '+' + countryCode,
        email: client.email,
      });
      this.fgUser.get('email')?.disable();
      this.fgUser.get('phone')?.disable();
    } else {
      this.fgUser.get('email')?.enable();
      this.fgUser.get('phone')?.enable();

      this.fgUser.patchValue({
        id: null,
        email: '',
        phone: '',
      });
    }
  }

  trackByFn(item: UserType) {
    return item.id;
  }

  editOrder() {
    if (this.fg.disabled) {
      this.fg.enable();
    } else {
      this.fg.disable();
    }
  }

  deleteOrder() {
    if (this.orderId) {
      if (
        confirm(
          'Are you sure you want to cancel this order? This action cannot be undone.'
        )
      ) {
        this.orderService.deleteOrder({id: this.orderId}).subscribe(
          (result) => {
            if (result) {
              this.toastrService.success(result);
              this.router.navigate;
            }
          },
          (error) => {
            this.toastrService.error(error);
          }
        );
      }
    }
  }

  verifyCancel(order: OrderType) {
    if (!order.createdAt) {
      return;
    }
    const diffInMilliseconds = Math.abs(
      new Date().getTime() - order.createdAt?.getTime()
    );
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    this.canCancel =
      order.status != 'canceled' &&
      order.status != 'aproved' &&
      diffInHours <= 24;
  }

  onSubmit(): void {
    this.fg.markAllAsTouched();
    if (this.fg.valid) {
      if (this.orderId && this.duplicate) {
        this.duplicateOder();
      } else if (this.orderId) {
        this.updatedOrder();
      } else {
        this.createOrder();
      }
    }
  }

  updatedOrder() {
    if (this.orderId) {
      this.orderService
        .updateOrder({
          orderID: this.orderId,
          studio: 'Jerusalem',
          status: this.fg.get('status')?.value,
          service: this.fg.get('pickService')?.value,
          quantityItemShipped: this.fg.get('quantity')?.value,
          quantityPhotosPerProduct: this.fg.get('numPhoto')?.value,
          typeOfPhotos: this.fg.get('typeStudios')?.value,
          typeOfProducts: this.fg.get('typeProducts')?.value,
          imageDimension: this.fg.get('imageDimensions')?.value,
          colorBackgroundAndShadow: this.fg.get('typeBackgrounds')?.value,
          extras: {
            type: this.fg.get('extras')?.value,
            briefing: this.fg.get('briefing')?.value,
            link: this.fg.get('link')?.value,
            image: this.fg
              .get('references')
              ?.value.map((control: { file: File }) => {
                return control.file;
              }),
          },
          imagesIdtoRemoved: Object.keys(this.imagesIdtoRemoved),
        })
        .subscribe((updated) => {
          if (updated) {
            this.toastrService.success(updated);
            this.location.back();
          }
        });
    }
    [];
  }

  enableDuplicatedOrder() {
    if (this.orderId) {
      this.fg.enable();
      this.duplicate = true;


    }
  }

  duplicateOder() {
    if (this.orderId) {
      this.orderService.duplicateOrder({
        orderID: this.orderId,
        service: this.fg.get('pickService')?.value,
        quantityItemShipped: this.fg.get('quantity')?.value,
        quantityPhotosPerProduct: this.fg.get('numPhoto')?.value,
        typeOfPhotos: this.fg.get('typeStudios')?.value,
        typeOfProducts: this.fg.get('typeProducts')?.value,
        imageDimension: this.fg.get('imageDimensions')?.value,
        colorBackgroundAndShadow: this.fg.get('typeBackgrounds')?.value,
        extras: {
          type: this.fg.get('extras')?.value,
          briefing: this.fg.get('briefing')?.value,
          link: this.fg.get('link')?.value,
          removeImages: Object.keys(this.imagesIdtoRemoved),
          addImages: this.fg
            .get('references')
            ?.value.map((control: { file: File }) => {
              return control.file;
            }),
        },
      }).subscribe((duplicated) => {
        if (duplicated) {
          this.toastrService.success(duplicated);
          this.location.back();
        }
      });
    }
  }

  remove(image: { id: string }) {
    if (
      confirm(
        'Are you sure you want to remove this reference image? This action cannot be undone.'
      )
    ) {
      this.imagesIdtoRemoved[image.id] = true;
    }
  }

  createOrder() {
    this.orderService
      .createOrderByAdmin({
        ownerID: this.fgUser.get('id')?.value,
        ...(!this.fgUser.get('id')?.value && {
          createNewUser: {
            fullName: this.fgUser.get('name')?.value,
            email: this.fgUser.get('email')?.value,
            phoneNumber: this.fgUser.get('phone')?.value,
          },
        }),
        studio: 'Jerusalem',
        service: this.fg.get('pickService')?.value,
        quantityItemShipped: this.fg.get('quantity')?.value,
        quantityPhotosPerProduct: this.fg.get('numPhoto')?.value,
        typeOfPhotos: this.fg.get('typeStudios')?.value,
        typeOfProducts: this.fg.get('typeProducts')?.value,
        imageDimension: this.fg.get('imageDimensions')?.value,
        colorBackgroundAndShadow: this.fg.get('typeBackgrounds')?.value,
        extras: {
          type: this.fg.get('extras')?.value,
          briefing: this.fg.get('briefing')?.value,
          link: this.fg.get('link')?.value,
          image: this.fg
            .get('references')
            ?.value.map((control: { file: File }) => {
              return control.file;
            }),
        },
      })
      .subscribe((created) => {
        if (created) {
          this.location.back();
        }
      });
  }

  onFileSelected(event: any): void {
    Array.from(event.target.files).forEach((file) => {
      this.referenceFile.push(
        this.formBuilder.group({
          file: [
            file,
            [
              FileFormatValidator([
                'image/jpeg',
                'image/png',
                'video/mp4',
                'image/jpg',
              ]),
              FileSizeValidator(),
            ],
          ],
        })
      );
    });
    this.referenceFile.markAllAsTouched();
  }

  removerItem(item: any): void {
    this.referenceFile.removeAt(item);
  }

  addNewClient(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fgUser.patchValue({
      id: null,
      name: this.name.searchTerm,
      email: '',
      phone: ''
    });
    this.fgUser.get('email')?.enable();
    this.name.close();
  }
}
