import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import {ForgotComponent} from './pages/forgot/forgot.component';
import {HowItWorksComponent} from './pages/how-it-works/how-it-works.component';
import {MyOrdersComponent} from './pages/my-orders/my-orders.component';
import {ContactComponent} from './pages/contact/contact.component';
import {RegisterComponent} from './pages/register/register.component';
import {BlankLayoutComponent} from './ui/blank-layout/blank-layout.component';
import {LayoutComponent} from './ui/layout/layout.component';
import {LoginComponent} from './pages/login/login.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from './pages/home/home.component';
import {OrderComponent} from './pages/order/order.component';
import {AuthGuard} from './guards/auth.guard';
import {ReviewOrderComponent} from './pages/review-order/review-order.component';
import {HasOrderToReviewGuard} from './guards/has-order-to-review.guard';
import {OrderFormComponent} from './shared/components/order-form/order-form.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {path: '', component: HomeComponent, title: 'e-shoots'},
      {path: 'home', component: HomeComponent, title: 'e-shoots'},
      {path: 'order', component: OrderComponent, title: 'New order | e-shoots'},
      {path: 'review', component: ReviewOrderComponent, title: 'Review | e-shoots', canActivate: [AuthGuard, HasOrderToReviewGuard]},
      {path: 'my-orders', component: MyOrdersComponent, title: 'My orders | e-shoots', canActivate: [AuthGuard]},
      {path: 'my-orders/:order_id', component: OrderFormComponent, title: 'My orders | e-shoots', canActivate: [AuthGuard]},
      {path: 'how-it-works', component: HowItWorksComponent, title: 'How it works | e-shoots'},
      {path: 'contact', component: ContactComponent, title: 'Contact | e-shoots'},
      {path: 'my-profile', component: RegisterComponent, data: {update: true}, title: 'My Profile | e-shoots', canActivate: [AuthGuard]},
    ],
  },
  {
    path: 'auth',
    component: BlankLayoutComponent,
    children: [
      {path: 'login', component: LoginComponent, title: 'Login | e-shoots'},
      {path: 'register', component: RegisterComponent, title: 'Register | e-shoots'},
      {path: 'forgot', component: ForgotComponent, title: 'Forgot Password | e-shoots'},
      {path: 'resetPassword', component: ResetPasswordComponent, title: 'Reset Password | e-shoots'},
    ],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },

  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
