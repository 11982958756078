import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function FileSizeValidator(): ValidatorFn {
   const maxSize = 524288000;
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const file = control.value as File;

      if (file.size > maxSize) {
        console.log(file);
        return { invalidSize: 'Invalid file size. Max 500MB' };
      }
    }
    return null;
  };
}
