import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-home-card',
  templateUrl: './home-card.component.html',
  styleUrls: ['./home-card.component.scss']
})
export class HomeCardComponent {
  @Input() feature: { frontImage: string, backImage: string, title: string, text: string };
  isOpened = false;
  remainingText = "";
  constructor() {
  }
  readMoreToggle(text: string) {
    if(!this.isOpened) {  
      this.remainingText = text.substring(303, 9999);
    } else {
      this.remainingText = "";
    }
    this.isOpened = !this.isOpened;
  }
}
