import {UpdateAdminsProfileInput, UpdateContactInformationInput, UserType,} from 'src/app/models/users.model';
import {allUsers, CreateUserInputType} from './../models/users.model';
import {catchError, map, Observable, of, tap} from 'rxjs';
import {Apollo, gql} from 'apollo-angular';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apollo: Apollo) {
  }

  createUsers(data: CreateUserInputType) {
    const CREATE_USER_MUTATION = gql`
      mutation createUser($input: CreateUserInput!) {
        createUser(CreateUserInput: $input) {
          id
          message
        }
      }
    `;

    return this.apollo
      .mutate<{ createUser: { id: string } }>({
        mutation: CREATE_USER_MUTATION,
        variables: {
          input: data,
        },
      })
      .pipe(map((response) => response.data));
  }

  getUsers(findAllUsersInput: {
    page: number;
    limit: number;
    search: string;
    client: boolean;
  }): Observable<allUsers> {
    const GET_ALL_USERS_QUERY = gql`
      query GetAllUsers($input: FindAllUsersInput!) {
        allUsers(FindAllUsersInput: $input) {
          users {
            id
            name
            email
            phoneNumber
            permission
            studio
            active
            lastAccess
          }
          currentPage
          totalPages
          totalUsers
        }
      }
    `;
    return this.apollo
      .query<{ allUsers: allUsers }>({
        query: GET_ALL_USERS_QUERY,
        variables: {input: findAllUsersInput},
      })
      .pipe(map((data) => data.data.allUsers));
  }

  getsearchUsers(autoCompleteUsers: { text: string }): Observable<UserType[]> {
    const AUTOCOMPLETE_USERS = gql`
      query AutoCompleteUsers($input: AutoCompleteUsersInput!) {
        autoCompleteUsers(AutoCompleteUsersInput: $input) {
          id
          name
          email
          phoneNumber
        }
      }
    `;

    return this.apollo
      .query<{ autoCompleteUsers: UserType[] }>({
        query: AUTOCOMPLETE_USERS,
        variables: {input: autoCompleteUsers},
      })
      .pipe(map((data) => data.data.autoCompleteUsers));
  }

  getUserById(getUserById: { id: string }): Observable<UserType> {
    const GET_USER_BY_ID = gql`
      query GetUserById($input: GetUserByIdInput!) {
        getUserById(GetUserByIdInput: $input) {
          id
          name
          email
          phoneNumber
          zipCode
          taxID
          city
          streetAddress
          country
          pictureUrl
          studio
          permission
          preferences
        }
      }
    `;
    return this.apollo
      .query<{ getUserById: UserType }>({
        query: GET_USER_BY_ID,
        variables: {input: getUserById},
      })
      .pipe(map((data) => data.data.getUserById));
  }

  updateContactInformation(
    user: UpdateContactInformationInput
  ): Observable<string> {
    const UPDATE_USER = gql`
      mutation UpdateContactInformation(
        $updateContactInformation: UpdateContactInformation!
      ) {
        updateContactInformation(
          UpdateContactInformation: $updateContactInformation
        )
      }
    `;
    return this.apollo
      .mutate<{ updateContactInformation: string }>({
        mutation: UPDATE_USER,
        variables: {
          updateContactInformation: {
            changeUserID: user.changeUserId,
            name: user.name,
            email: user.email,
            zipCode: Number.parseInt(`${user.zipCode ?? ''}`, 10),
            taxID: Number.parseInt(`${user.taxID}`, 10),
            city: user.city,
            streetAddress: user.streetAddress,
            country: user.country,
            phoneNumber: user.phoneNumber,
            preferences: user.preferences,
          },
        },
      })
      .pipe(map((response) => response.data?.updateContactInformation ?? ''));
  }

  createClient(
    user: UpdateContactInformationInput
  ): Observable<string> {
    const CREATE_CLIENT = gql`
      mutation createClient(
        $createClientInput: CreateClientInput!
      ) {
        createClient(
          CreateClientInput: $createClientInput
        ){
        id
        message
        }
      }
    `;
    console.log({user});
    return this.apollo
      .mutate<{ createClient: { id: string, message: string } }>({
        mutation: CREATE_CLIENT,
        variables: {
          createClientInput: {
            fullName: user.name,
            email: user.email,
            zipCode: Number.parseInt(`${user.zipCode ?? ''}`, 10),
            taxID: Number.parseInt(`${user.taxID}`, 10),
            city: user.city,
            streetAddress: user.streetAddress,
            country: user.country,
            phoneNumber: user.phoneNumber,
          },
        },
      })
      .pipe(tap(console.log), map((response) => response.data?.createClient?.message ?? ''));
  }

  updateAdminsProfile(user: UpdateAdminsProfileInput): Observable<string> {
    const UPDATE_ADMIN_PROFILE = gql`
      mutation updateAdminsProfile($input: UpdateAdminsProfileInput!) {
        updateAdminsProfile(UpdateAdminsProfileInput: $input)
      }
    `;
    return this.apollo
      .mutate<{ updateAdminsProfile: string }>({
        mutation: UPDATE_ADMIN_PROFILE,
        variables: {
          input: user,
        },
      })
      .pipe(map((response) => response.data?.updateAdminsProfile ?? ''));
  }

  setProfilePicture(setPictureProfile: {
    image: File;
  }): Observable<string | null> {
    const SET_PROFILE_PICTURE = gql`
      mutation setPictureProfile($setPictureProfile: SetPictureProfileInput!) {
        setPictureProfile(SetPictureProfileInput: $setPictureProfile)
      }
    `;
    return this.apollo
      .mutate<{ setPictureProfile: string }>({
        mutation: SET_PROFILE_PICTURE,
        variables: {
          setPictureProfile,
        },
      })
      .pipe(
        catchError((err) => {
          return of(null);
        }),
        map((result) => {
          if (!result || !result.data) {
            return null;
          }

          return result.data.setPictureProfile;
        })
      );
  }

  toggleUserStatus(toggleInput: {
    userId: string;
    toggle: boolean
  }): Observable<string | null> {
    const TOGGLE_USER_STATUS = gql`
      mutation toggleUserStatus($toggleInput: ToggleInput!) {
        toggleUserStatus(ToggleInput: $toggleInput)
      }
    `;
    return this.apollo
      .mutate<{ toggleUserStatus: string }>({
        mutation: TOGGLE_USER_STATUS,
        variables: {
          toggleInput,
        },
      })
      .pipe(
        catchError((err) => {
          return of(null);
        }),
        map((result) => {
          if (!result || !result.data) {
            return null;
          }

          return result.data.toggleUserStatus;
        })
      );
  }
}
