import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-how-it-works-card',
  templateUrl: './how-it-works-card.component.html',
  styleUrls: ['./how-it-works-card.component.scss']
})
export class HowItWorksCardComponent {
  @Input() img_src = '';
  @Input() title = '';
  @Input() text = '';

  constructor() {
  }


}
