import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpStatusCode} from '@angular/common/http';
import {first, switchMap} from 'rxjs/operators';
import {AuthService} from './services/auth.service';
import {catchError, Observable, of} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';

@Injectable()
export class ApolloInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(private authService: AuthService, private toastrService: ToastrService, private router: Router) {
  }

  // tslint:disable-next-line:typedef
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const isGraphql = req.url.includes('/graphql');
    const isImage = req.url.includes('/images');

    if (!isGraphql && !isImage) {
      return next.handle(req);
    }


    return this.authService.token$
      .pipe(
        first(),
        switchMap(token => {
          const clone = this.addToken(req, token?.accessToken ?? ``);
          if (!token?.accessToken) {
            return next.handle(clone);
          }
          return next.handle(clone).pipe(
            // @ts-expect-error
            switchMap((response: HttpResponse) => {
              if (!response.body?.errors) {
                return of(response);
              }
              const errorCode = response.body?.errors[0].code;
              const firstMessage = response.body?.errors[0]?.message;
              const isInvalidToken = errorCode === HttpStatusCode.Unauthorized && firstMessage === 'Invalid Token';
              const isInvalidRefreshToken = errorCode === HttpStatusCode.Unauthorized && firstMessage === 'Invalid refresh token';
              const isUnauthorized = errorCode === HttpStatusCode.Unauthorized && firstMessage === 'Anauthorization';
              const isAnotherError = !isInvalidRefreshToken && !isInvalidToken;
              if (isInvalidToken) {
                return this.handleInvalidToken(req, next);
              }

              if (isUnauthorized || isInvalidRefreshToken) {
                this.toastrService.error('Your session has expired', '', {
                  enableHtml: true
                });
                this.router.navigate(['/auth/login']);
                this.authService.signOut();
                return of(response);
              }

              if (isAnotherError) {
                const message = response.body.errors.map((error: { message: string }) => `<p>${error.message}</p>`).join('');
                this.toastrService.error(message, '', {
                  enableHtml: true
                });
              }
              throw new HttpErrorResponse(response);
            }), catchError((error: HttpErrorResponse) => {
              return of(null);
            }));
        })
      );
  }


  private handleInvalidToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.isRefreshing) {
      return next.handle(request);
    }

    this.isRefreshing = true;
    return this.authService.token$.pipe(
      first(),
      switchMap(token => this.authService.refreshToken({refreshToken: token?.refreshToken ?? ''})),
      switchMap((token => {
        this.isRefreshing = false;
        if (!token) {
          return next.handle(request);
        }
        this.authService.setToken(token);
        return next.handle(this.addToken(request, token));
      }))
    );

  }

  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    if (!environment.production) {
      console.log(`Bearer ${token}`);
    }
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'Apollo-Require-Preflight': 'true'
      }
    });
  }
}
