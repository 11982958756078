import {Pipe, PipeTransform} from '@angular/core';
import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Pipe({
  name: 'phoneFormatter',
})
export class PhoneFormatterPipe implements PipeTransform {
  transform(
    phone: string | undefined,
  ): string {
    if (!phone) {
      return '';
    }
    try {
      const phoneNumber = phoneNumberUtil.parse(phone);
      const ddi = phoneNumber.getCountryCodeOrDefault();
      const number = phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);
      return `+${ddi} ${number}`;
    } catch (error) {
      return phone ?? '';
    }
  }
}
