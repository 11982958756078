import { catchError, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
})
export class ForgotComponent implements OnInit {
  form: FormGroup;
  message = '';
  isLoading = false;
  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService
  ) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {}

  forgotPassword() {
    const email = this.form.get('email')?.value;
    if (email) {
      this.isLoading = true;
      this.authService
        .forgotPassword(email)
        .pipe(catchError((err) => of(null)))
        .subscribe((result) => {
          this.isLoading = false;
          if (result) {
            this.message = result;
          }
        });
    }
  }
}
