<section class="container">
  <div class="d-flex gap-2 flex-column flex-md-row justify-content-between">
    <div class="">
      <button
        type="button"
        class="btn btn-meddium btn-outline-black ps-5 pe-5 text-nowrap icon icon-arrow-left"
        backButton
      >
        Back
      </button>
    </div>

    <div class="d-flex gap-2 flex-row">

      <button
        *ngIf="orderId"
        type="button"
        class="btn flex-grow-1 btn-outline-primary ps-2 pe-2 text-nowrap"
        (click)="enableDuplicatedOrder()"
      >
        <span class="d-inline-block d-md-none">Duplicate</span>
        <span class="d-none d-md-inline-block"> Duplicate Order</span>
      </button>
      <button
        *ngIf="orderId"
        type="button"
        class="btn flex-grow-1 btn-outline-primary ps-2 pe-2 text-nowrap"
        (click)="editOrder()"
      >
        <span class="d-inline-block d-md-none">Edit</span>
        <span class="d-none d-md-inline-block"> Edit Order</span>
      </button>
      <button
        *ngIf="orderId"
        type="button"
        class="btn flex-grow-1 btn-outline-primary ps-2 pe-2 text-nowrap"
        (click)="deleteOrder()"
        [disabled]="!canCancel"
      >
        <span class="d-inline-block d-md-none">Cancel</span>
        <span class="d-none d-md-inline-block"> Cancel Order</span>
      </button>
    </div>
  </div>
  <form class="row mt-4" action="">
    <div class="col-12 col-lg-10 offset-lg-1 col-xl-10 col-xl-1">
      <h2 class="mb-4">{{duplicate ? 'Duplicate Order' : 'Order'}}</h2>
      <form class="card" [formGroup]="fgUser">
        <div class="card-body">
          <div class="form-floating form-floating-select mb-2">
            <ng-select
              [items]="client$ | async"
              bindLabel="name"
              bindValue="name"
              [loading]="clientLoading"
              [multiple]="false"
              [disabled]="!edit"
              formControlName="name"
              [addTag]="false"
              [hideSelected]="true"
              [trackByFn]="trackByFn"
              [minTermLength]="4"
              [typeahead]="clientInput$"
              (change)="setClient($event)"
              #name
            >
              <ng-template ng-option-tmp let-item="item"
              >{{ item.name }} <br/>
                <small>{{ item.email }}</small>
              </ng-template>

              <ng-template ng-footer-tmp>
                <button
                  type="button"
                  (click)="addNewClient($event)"
                  class="btn btn-success btn-sm fw-semibold"
                >
                  Add New Client
                </button>
              </ng-template>
            </ng-select>
            <label for="">Full Name</label>
          </div>
          <app-validation-message [control]="fgUser.get('name')" label="Full Name">
          </app-validation-message>

          <div class="mb-3">
            <input
              type="email"
              formControlName="email"
              class="form-control icon icon-email"
              placeholder="email"
            />
            <i></i>
          </div>
          <app-validation-message [control]="fgUser.get('email')" label="Email">
          </app-validation-message>
          <div class="mb-3">
            <app-input-tel [countryControl]="fgUser.controls['countryCode']" [entryControl]="fgUser.controls['phone']"></app-input-tel>
          </div>
          <app-validation-message [control]="fgUser.get('phone')" label="Full Name">
          </app-validation-message>
        </div>

      </form>
    </div>
  </form>

  <form class="row mt-2" [formGroup]="fg" (ngSubmit)="onSubmit()">
    <div class="col-12 col-lg-10 offset-lg-1 col-xl-10 col-xl-1">
      <div class="card">
        <div class="card-header" *ngIf="order">
          <div class="d-flex justify-content-start gap-5 ">
            <p class="card-text">
              Resume Order <span class="d-block d-md-inline d-lg-inline text-primary"> {{ order.id }}</span>
            </p>
            <p class="card-text">
              Client <span class="d-block d-md-inline d-lg-inline  text-primary"> {{ order.owner?.name }}</span>
            </p>
          </div>
        </div>
        <div class="card-body">


          <div class="form-floating mb-2">
            <select class="form-select" formControlName="pickService">
              <option
                [value]="service.title"
                *ngFor="let service of pickServiceOptions$ | async"
              >
                {{ service.title }}
              </option>
            </select>
            <label for="floatingSelect">Pick a Service</label>
          </div>
          <app-validation-message errorStyle="inline" [control]="fg.get('pickService')" label="Pick a Service">
          </app-validation-message>

          <div class="row g-2 mb-2">
            <div class="col-md">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  formControlName="quantity"
                />
                <label>Quantity of Items Shipped</label>
              </div>
            </div>

            <div class="col-md">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  formControlName="numPhoto"
                />
                <label>Num Of Photos Per Product</label>
              </div>
            </div>
          </div>
          <app-validation-message errorStyle="inline" [control]="fg.get('quantity')" label="Quantity of Items Shipped">
          </app-validation-message>
          <app-validation-message errorStyle="inline" [control]="fg.get('numPhoto')" label="Num Of Photos Per Product">
          </app-validation-message>

          <div class="form-floating form-floating-select mb-2">
            <ng-select
              [items]="photosOptions$ | async"
              bindLabel="title"
              bindValue="title"
              [multiple]="true"
              formControlName="typeStudios"
              [addTag]="true"
            >
            </ng-select>
            <label for="">Select Studio - Type of Photo</label>
          </div>
          <app-validation-message errorStyle="inline" [control]="fg.get('typeStudios')" label="Type of Photo">
          </app-validation-message>

          <div class="form-floating form-floating-select mb-2">
            <ng-select
              [items]="typeProduct"
              bindLabel="name"
              bindValue="name"
              [multiple]="true"
              [disabled]="!edit"
              formControlName="typeProducts"
              [addTag]="true"
            >
            </ng-select>
            <label for="">Type of Product</label>
          </div>
          <app-validation-message errorStyle="inline" [control]="fg.get('typeProducts')" label="Type of Product">
          </app-validation-message>

          <div class="form-floating form-floating-select mb-2">
            <ng-select
              [items]="imageDimension"
              bindLabel="name"
              bindValue="name"
              [multiple]="false"
              [disabled]="!edit"
              formControlName="imageDimensions"
              [addTag]="true"
            >
            </ng-select>
            <label for="">Image Dimensions</label>
          </div>
          <app-validation-message errorStyle="inline" [control]="fg.get('imageDimensions')" label="Image Dimensions">
          </app-validation-message>

          <div class="form-floating form-floating-select mb-2">
            <ng-select
              [items]="typeBackground"
              bindLabel="name"
              bindValue="name"
              [multiple]="true"
              [disabled]="!edit"
              formControlName="typeBackgrounds"
              [addTag]="true"
            >
            </ng-select>
            <label for="">Color Background & Shadow</label>
          </div>
          <app-validation-message errorStyle="inline" [control]="fg.get('typeBackgrounds')"
                                  label="Color Background & Shadow">
          </app-validation-message>

          <div class="form-floating form-floating-select mb-2">
            <ng-select
              [items]="extra"
              bindLabel="name"
              bindValue="name"
              [multiple]="false"
              [disabled]="!edit"
              formControlName="extras"
              [addTag]="true"
            >
            </ng-select>
            <label for="">Extras</label>
          </div>
          <app-validation-message errorStyle="inline" [control]="fg.get('extras')" label="Extras">
          </app-validation-message>

          <div class="mb-2" *ngIf="!fg.disabled">
            <!-- <h2 class="text-black mt-4 mt-lg-0 mb-4">Reference add link or photos, files</h2> -->
            <label
              for="upload"
              class="form-input form-input--light label-file text-start w-100 mb-3"
            >
              Click to upload reference image or video
            </label>
            <input
              id="upload"
              class="d-none"
              type="file"
              (change)="onFileSelected($event)"
              multiple="multiple"
            />

            <ul class="list-unstyled" formArrayName="references">
              <li
                class="file-list"
                *ngFor="let item of referenceFile.controls; let i = index"
              >
                <div class="d-flex justify-content-start gap-2">
                  <app-media-preview
                    [file]="item.value.file"
                  ></app-media-preview>
                  <div class="text-start">
                    <strong>{{ item.value.file.name }}</strong>
                    <p>{{ item.value.file.size }}</p>
                  </div>
                  <button
                    (click)="removerItem(i)"
                    class="btn-close ms-auto"
                  ></button>
                </div>
              </li>
            </ul>
            <app-validation-message
              [control]="fg.get('references')"
              label="reference File"
            ></app-validation-message>
          </div>
          <div class="information px-2" *ngIf="order">
            <small class="d-block mb-2 text-gray">References</small>
            <div class="d-flex flex-nowrap gap-2">
              <div *ngFor="let image of order.extras.images" class="position-relative">
                <ng-container *ngIf="!imagesIdtoRemoved[image.id]">
                  <app-media [link]="image.pictureUrl"></app-media>
                  <button type="button" *ngIf="fg.enabled" (click)="remove(image)"
                          class="btn btn-xs btn-danger btn-remove-reference">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </button>
                </ng-container>
              </div>
            </div>
            <p></p>
          </div>

          <div class="form-floating mb-2">
            <input class="form-control" formControlName="link"/>
            <label>Link</label>
          </div>
          <app-validation-message errorStyle="inline" [control]="fg.get('link')" label="Link">
          </app-validation-message>
          <div class="form-floating mb-2">
            <textarea
              class="form-control"
              formControlName="briefing"
              style="height: 100px"
            ></textarea>
            <label>Briefing</label>
          </div>
          <app-validation-message errorStyle="inline" [control]="fg.get('briefing')" label="Briefing">
          </app-validation-message>
        </div>
      </div>
    </div>
    <div class="text-center mt-5 mb-5" *ngIf="fg.enabled">
      <app-main-action-button [label]="duplicate ? 'Duplicate Order' : 'Save'"></app-main-action-button>
    </div>
  </form>
</section>
