import { AllOrders, CreateOrderByAdminInput, DuplicateOrderInput, OrderType, UpdatedOrderInput } from './../models/order.model';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { catchError, map, Observable, of } from 'rxjs';
import { OrderInputType } from '../models/order.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { format } from 'date-fns/fp';
import { parse, parseISO } from 'date-fns';
import { format as formatTz, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import { environment } from '../../environments/environment'


@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private order: OrderInputType | null;
  private orderInProgress: { step: number; formValues: any[] } | null;

  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private router: Router,
    private toastrService: ToastrService
  ) {
  }

  createOrder(): Observable<boolean> {
    if (!this.order) {
      throw new Error('There are no orders to create');
    }

    const CREATE_ORDER_MUTATION = gql`
      mutation createOrder(
        $studio: String!
        $service: String!
        $typeOfPhotos: [String!]!
        $typeOfProducts: [String!]!
        $imageDimension: String!
        $quantityPhotosPerProduct: String!
        $quantityItemShipped: String!
        $colorBackgroundAndShadow: [String!]!
        $extras: Extras!
      ) {
        createOrder(
          input: {
            studio: $studio
            service: $service
            typeOfPhotos: $typeOfPhotos
            typeOfProducts: $typeOfProducts
            imageDimension: $imageDimension
            quantityPhotosPerProduct: $quantityPhotosPerProduct
            quantityItemShipped: $quantityItemShipped
            colorBackgroundAndShadow: $colorBackgroundAndShadow
            extras: $extras
          }
        )
      }
    `;

    return this.apollo
      .mutate<{ createOrder: boolean }>({
        mutation: CREATE_ORDER_MUTATION,
        variables: {
          studio: 'Jerusalem',
          service: this.order.service,
          typeOfPhotos: this.order.typeOfPhotos,
          typeOfProducts: this.order.typeOfProducts,
          imageDimension: `${this.order.imageDimension}`,
          quantityPhotosPerProduct: `${this.order.quantityPhotosPerProduct}`,
          quantityItemShipped: `${this.order.quantityItemShipped}`,
          colorBackgroundAndShadow: this.order.colorBackgroundAndShadow,
          extras: {
            type: this.order.extras.type,
            link: this.order.extras.link,
            briefing: this.order.extras.briefing,
            ...(this.order.extras.images && {
              image: this.order.extras.images,
            }),
          },
        },
      })
      
      .pipe(
        map((response) => response?.data?.createOrder ?? false),
        map((created) => {
          if (created) {
            this.unstashOrder();
            this.router.navigate(['/my-orders']).then(() => {
              this.orderInProgress = null;
            });
          }
          return created;
        })
      );
  }

  createOrderByAdmin(order: CreateOrderByAdminInput) {
    const CREATE_ORDER_ADMIN_MUTATION = gql`
      mutation CreateOrderByAdmin($input: CreateOrderByAdminInput!) {
        CreateOrderByUserAdmin(CreateOrderByAdminInput: $input)
      }
    `;
    return this.apollo
      .mutate<{ CreateOrderByUserAdmin: boolean }>({
        mutation: CREATE_ORDER_ADMIN_MUTATION,
        variables: {
          input: order,
        },
      })
      .pipe(map((response) => response?.data?.CreateOrderByUserAdmin ?? false));
  }


  myOrders(): Observable<OrderType[]> {
    const MY_ORDERS = gql`
      query {
        myOrders {
          id
          service
          status
          delivery
          priceQuote
          invoice
          deadLine
          quantityItemShipped
          quantityPhotosPerProduct
          typeOfPhotos
          typeOfProducts
          extras {
            type
            briefing
            link
            images {
              id
              pictureUrl
            }
          }
          imageDimension
          colorBackgroundAndShadow
          createdAt
          updatedAt
        }
      }
    `;
    return this.apollo
      .query<{ myOrders: OrderType[] }>({
        query: MY_ORDERS,
      })
      .pipe(
        catchError((err) => of(null)),
        map((data) => {
          if (!data) {
            return [];
          }
          return data.data.myOrders.map((order) => {
            return {
              ...order,
              createdAt: order.createdAt ? new Date(order.createdAt) : null,
              updatedAt: order.updatedAt ? new Date(order.updatedAt) : null,
            };
          });
        })
      );
  }

  stashOrder(order: OrderInputType): void {
    this.order = order;
  }

  unstashOrder(): void {
    this.order = null;
  }

  getOrderInProgress(): { step: number; formValues: any[] } | null {
    return this.orderInProgress;
  }

  setOrderInProgress(
    orderInProgress: { step: number; formValues: any[] } | null
  ): void {
    this.orderInProgress = orderInProgress;
  }

  getOrder(): OrderInputType | null {
    return this.order;
  }

  getAllOrders(findAllOrdersInput: {
    page: number;
    limit: number;
    search: string;
    orderByStudio?: 'asc' | 'desc'
    orderByClient?: 'asc' | 'desc'
    orderByCategory?: 'asc' | 'desc'
    orderByStatus?: 'asc' | 'desc'
    orderByLog?: 'asc' | 'desc'
    orderByDeadLine?: 'asc' | 'desc'

  }): Observable<AllOrders> {
    const GET_ALL_ORDERS = gql`
      query GetAllOrders($input: FindAllOrdersInput!) {
        findAllOrders(FindAllOrdersInput: $input) {
          orders {
            id
            service
            quantityItemShipped
            quantityPhotosPerProduct
            status
            visible
            delivery
            invoice
            typeOfPhotos
            typeOfProducts
            deadLine
            priceQuote
            extras {
              type
              briefing
              images {
                id
                pictureUrl
              }
              link
            }
            imageDimension
            colorBackgroundAndShadow
            createdAt
            updatedAt
            log
            owner {
              id
              name
            }
          }
          currentPage
          totalPages
        }
      }
    `;
    return this.apollo
      .query<{ findAllOrders: AllOrders }>({
        query: GET_ALL_ORDERS,
        variables: { input: findAllOrdersInput },
      })
      .pipe(
        map((data) => {
          return {
            ...data.data.findAllOrders,
            orders: this.mapJsonResponseToOrders(data.data.findAllOrders.orders),
          };
        })
      );
  }

  checkNewOrders(checkNewOrdersInput: {
    startDate: Date;
  }): Observable<OrderType[]> {
    const CHECK_NEW_ORDERS = gql`
      query checkNewOrders($input: CheckNewOrdersInput!) {
        checkNewOrders(CheckNewOrdersInput: $input) {
          orders {
            id
            service
            quantityItemShipped
            quantityPhotosPerProduct
            status
            visible
            delivery
            invoice
            typeOfPhotos
            typeOfProducts
            deadLine
            extras {
              type
              briefing
              images {
                id
                pictureUrl
              }
              link
            }
            imageDimension
            colorBackgroundAndShadow
            createdAt
            updatedAt
            log
            owner {
              id
              name
            }
          }
        }
      }
    `;


    const dateToIsrael = this.dateToIsraelTz(checkNewOrdersInput.startDate);
    const israelString = this.israelDateStringTz(dateToIsrael);
    return this.apollo
      .query<{ checkNewOrders: AllOrders }>({
        query: CHECK_NEW_ORDERS,
        variables: {
          input: {
            ...checkNewOrdersInput,
            startDate: israelString
          }
        },
      })
      .pipe(
        map((data) => this.mapJsonResponseToOrders(data.data.checkNewOrders.orders))
      );
  }

  getOrderById(findOrderInput: { id: string }): Observable<OrderType> {
    const GET_ORDER_ID = gql`
      query GetOrderById($input: FindOrderInput!) {
        findOrderById(FindOrderInput: $input) {
          id
          service
          quantityItemShipped
          quantityPhotosPerProduct
          status
          visible
          delivery
          invoice
          typeOfPhotos
          typeOfProducts
          extras {
            type
            briefing
            images {
              id
              pictureUrl
            }
            link
          }
          imageDimension
          colorBackgroundAndShadow
          createdAt
          updatedAt
          log
          owner {
            id
            name
            email
            phoneNumber
          }
        }
      }
    `;
    return this.apollo
      .query<{ findOrderById: OrderType }>({
        query: GET_ORDER_ID,
        variables: { input: findOrderInput },
      })
      .pipe(
        map((data) => {
          return {
            ...data.data.findOrderById,
            createdAt: new Date(`${data.data.findOrderById.createdAt}`),
          };
        })
      );
  }

  getOrdersByUserId(findOrdersByUserId: {
    userId: string;
  }): Observable<OrderType[]> {
    const GET_ORDER_USER_ID = gql`
      query FindOrdersByUserId($input: FindOrderByUserIdInput!) {
        findOrdersByUserId(FindOrderByUserIdInput: $input) {
          id
          owner {
            id
            name
            email
            phoneNumber
          }
          service
          delivery
          quantityItemShipped
          quantityPhotosPerProduct
          status
          invoice
          visible
          deadLine
          typeOfPhotos
          typeOfProducts
          extras {
            type
            briefing
            images {
              id
              pictureUrl
            }
            link
          }
          imageDimension
          colorBackgroundAndShadow
          createdAt
          updatedAt
          log
        }
      }
    `;
    return this.apollo
      .query<{ findOrdersByUserId: OrderType[] }>({
        query: GET_ORDER_USER_ID,
        variables: { input: findOrdersByUserId },
      })
      .pipe(map((data) => data.data.findOrdersByUserId));
  }

  updateOrder(updatedOrderInput: UpdatedOrderInput): Observable<string | null> {
    const UPDATE_ORDER_MUTATION = gql`
      mutation updateOrder($input: UpdatedOrderInput!) {
        updateOrder(input: $input)
      }
    `;
    return this.apollo
      .mutate<{ updateOrder: string }>({
        mutation: UPDATE_ORDER_MUTATION,
        variables: {
          input: updatedOrderInput,
        },
      })
      .pipe(
        catchError((err) => {
          return of(null);
        }),
        map((result) => {
          if (!result || !result.data) {
            return null;
          }

          return result.data.updateOrder;
        })
      );
  }

  duplicateOrder(duplicateOrderInput: DuplicateOrderInput): Observable<string | null> {
    const DUPLICATE_ORDER_MUTATION = gql`
    mutation duplicateOrder($duplicateOrderInput: DuplicateOrderInput!){
      duplicateOrder(DuplicateOrderInput: $duplicateOrderInput)
    }
    `;
    return this.apollo
      .mutate<{ duplicateOrder: string }>({
        mutation: DUPLICATE_ORDER_MUTATION,
        variables: {
          duplicateOrderInput
        },
      }).pipe(
        catchError((err) => {
          return of(null);
        }),
        map((result) => {
          if (!result || !result.data) {
            return null;
          }
          return result.data.duplicateOrder;
        })
      );
  }

  deleteOrder(cancelOrderInput: { id: string }): Observable<string> {
    const DELETE_ORDER_MUTATION = gql`
      mutation CancelOrder($input: CancelOrderInput!) {
        cancelOrder(CancelOrderInput: $input)
      }
    `;

    return this.apollo
      .mutate<{ cancelOrder: any }>({
        mutation: DELETE_ORDER_MUTATION,
        variables: {
          input: cancelOrderInput,
        },
      })
      .pipe(
        catchError((err) => {
          return of(null);
        }),
        map((result) => {
          if (!result || !result.data) {
            return null;
          }

          return result.data?.cancelOrder;
        })
      );
  }

  setOrderStatus(setOrderStatusInput: {
    orderID: string;
    status: string;
  }): Observable<string | null> {
    const SET_ORDER_STATUS = gql`
      mutation setOrderStatus($setOrderStatusInput: SetOrderStatusInput!) {
        setOrderStatus(SetOrderStatusInput: $setOrderStatusInput)
      }
    `;
    return this.apollo
      .mutate<{ setOrderStatus: string }>({
        mutation: SET_ORDER_STATUS,
        variables: {
          setOrderStatusInput,
        },
      })
      .pipe(
        catchError((err) => {
          return of(null);
        }),
        map((result) => {
          if (!result || !result.data) {
            return null;
          }

          return result.data.setOrderStatus;
        })
      );
  }

  setOrderVisible(setVisibleOrderInput: {
    orderID: string;
    visibled: boolean;
  }): Observable<string | null> {
    const SET_ORDER_VISIBLE = gql`
      mutation SetVisibleOrder($setVisibleOrderInput: SetVisibleOrderInput!) {
        setVisibleOrder(SetVisibleOrderInput: $setVisibleOrderInput)
      }
    `;
    return this.apollo
      .mutate<{ setVisibleOrderInput: string }>({
        mutation: SET_ORDER_VISIBLE,
        variables: {
          setVisibleOrderInput,
        },
      })
      .pipe(
        catchError((err) => {
          return of(null);
        }),
        map((result) => {
          if (!result || !result.data) {
            return null;
          }
          return `Delivery link is ${setVisibleOrderInput.visibled ? 'now visible' : 'not visible'
            }`;
        })
      );
  }

  setOrderDelivery(setDeliveryOrderInput: {
    orderID: string;
    link: string;
  }): Observable<string | null> {
    const SET_ORDER_DELIVERY = gql`
      mutation SetDeliveryOrder(
        $setDeliveryOrderInput: SetDeliveryOrderInput!
      ) {
        setDeliveryOrder(SetDeliveryOrderInput: $setDeliveryOrderInput)
      }
    `;
    return this.apollo
      .mutate<{ setDeliveryOrder: string }>({
        mutation: SET_ORDER_DELIVERY,
        variables: {
          setDeliveryOrderInput,
        },
      })
      .pipe(
        catchError((result) => {
          return of(null);
        }),
        map((result) => {
          if (!result || !result.data) {
            return null;
          }
          return 'Delivery link successfully updated';
        })
      );
  }

  setInvoiceOrder(setInvoiceOrderInput: {
    orderID: string;
    invoice: File;
  }): Observable<string | null> {
    const SET_INVOICE_ORDER = `
  mutation setInvoiceOrder($setInvoiceOrderInput: SetInvoiceOrderInput!) {
    setInvoiceOrder(
      SetInvoiceOrderInput: $setInvoiceOrderInput
    )
  }
`;
    const operations = {
      query: SET_INVOICE_ORDER,
      variables: {
        setInvoiceOrderInput: {
          orderID: setInvoiceOrderInput.orderID,
          invoice: null,
        },
      },
    };

    const fileMap = {
      invoice: ['variables.setInvoiceOrderInput.invoice'],
    };

    const fd = new FormData();
    // Operations
    fd.append('operations', JSON.stringify(operations));
    // Map
    fd.append('map', JSON.stringify(fileMap));
    // Files
    fd.append(
      'invoice',
      setInvoiceOrderInput.invoice,
      setInvoiceOrderInput.invoice?.name
    );
    return this.http
      .post<{ data: { setInvoiceOrder: string } }>(
        environment.baseURL,
        fd
      )
      .pipe(map((response) => response.data.setInvoiceOrder));
  }


  setPriceQuote(setPriceQuoteInput: {
    orderID: string;
    priceQuote: File;
  }): Observable<string | null> {
    const SET_PRICE_QUOTE_FILE = `
  mutation setPriceQuoteOrder($setPriceQuoteInput: SetPriceQuoteInput!) {
    setPriceQuoteOrder(
      SetPriceQuoteInput: $setPriceQuoteInput
    )
  }
`;
    const operations = {
      query: SET_PRICE_QUOTE_FILE,
      variables: {
        setPriceQuoteInput: {
          orderID: setPriceQuoteInput.orderID,
          priceQuote: null,
        },
      },
    };

    const fileMap = {
      priceQuote: ['variables.setPriceQuoteInput.priceQuote'],
    };

    const fd = new FormData();
    // Operations
    fd.append('operations', JSON.stringify(operations));
    // Map
    fd.append('map', JSON.stringify(fileMap));
    // Files
    fd.append(
      'priceQuote',
      setPriceQuoteInput.priceQuote,
      setPriceQuoteInput.priceQuote?.name
    );
    return this.http
      .post<{ data: { setPriceQuoteOrder: string } }>(
        environment.baseURL,
        fd
      )
      .pipe(map((response) => response.data.setPriceQuoteOrder));
  }

  removeInvoice(setInvoiceOrderInput: {
    orderID: string;
  }): Observable<string | null> {
    const SET_INVOICE_ORDER = gql`
      mutation setInvoiceOrder($setInvoiceOrderInput: SetInvoiceOrderInput!) {
        setInvoiceOrder(SetInvoiceOrderInput: $setInvoiceOrderInput)
      }
    `;
    return this.apollo
      .mutate<{ setInvoiceOrder: string }>({
        mutation: SET_INVOICE_ORDER,
        variables: {
          setInvoiceOrderInput,
        },
      })
      .pipe(
        map((result) => {
          if (!result || !result.data) {
            return null;
          }

          return result.data.setInvoiceOrder;
        })
      );
  }

  removePriceQuote(setPriceQuoteInput: { orderID: string }): Observable<string | null> {
    const SET_PRICE_QUOTE_FILE = gql`
      mutation setPriceQuoteOrder($setPriceQuoteInput: SetPriceQuoteInput!) {
        setPriceQuoteOrder(SetPriceQuoteInput: $setPriceQuoteInput)
      }
    `;
    return this.apollo
      .mutate<{ setPriceQuoteOrder: string }>({
        mutation: SET_PRICE_QUOTE_FILE,
        variables: {
          setPriceQuoteInput,
        },
      })
      .pipe(
        map((result) => {
          if (!result || !result.data) {
            return null;
          }

          return result.data.setPriceQuoteOrder;
        })
      );
  }

  setDeadLineOrder(setDeadLineOrderInput: {
    orderID: string;
    deadLine: Date;
  }): Observable<string | null> {
    const SET_DEADLINE = gql`
      mutation setDeadLineOrder(
        $setDeadLineOrderInput: SetDeadLineOrderInput!
      ) {
        setDeadLineOrder(SetDeadLineOrderInput: $setDeadLineOrderInput)
      }
    `;
    return this.apollo
      .mutate<{ setDeadLineOrder: string }>({
        mutation: SET_DEADLINE,
        variables: {
          setDeadLineOrderInput,
        },
      })
      .pipe(
        map((result) => {
          if (!result || !result.data) {
            return null;
          }

          return result.data.setDeadLineOrder;
        })
      );
  }

  openInvoice(order: OrderType) {
    switch (typeof order.invoice) {
      case 'string':
        this.http.get(order.invoice, { responseType: 'blob' }).subscribe((file) => {
          const invoiceUrl = URL.createObjectURL(file);

          window.open(invoiceUrl);
        });
        break;

      case 'object':
        const invoiceUrl = URL.createObjectURL(order.invoice as File);
        window.open(invoiceUrl);

        break;

    }
  }

  openPriceQuote(order: OrderType) {

    console.log(order)
    switch (typeof order.priceQuote) {
      case 'string':
        this.http.get(order.priceQuote, { responseType: 'blob' }).subscribe((file) => {
          const invoiceUrl = URL.createObjectURL(file);

          window.open(invoiceUrl);
        });
        break;

      case 'object':
        const invoiceUrl = URL.createObjectURL(order.invoice as File);
        window.open(invoiceUrl);

        break;

    }
  }

  private mapJsonResponseToOrders(orders: OrderType[]): OrderType[] {
    return orders.map((order) => {
      if (order.deadLine) {
        order.deadLine = new Date(`${order.deadLine}`);
        order.ngbDeadline = {
          year: Number.parseInt(format('yyyy', order.deadLine), 10),
          month: Number.parseInt(format('MM', order.deadLine), 10),
          day: Number.parseInt(format('dd', order.deadLine), 10),
        };
      }

      order.createdAt = parse(`${order.createdAt}`.substring(0, 19), 'yyyy-MM-dd\'T\'HH:mm:ss', new Date());
      return order;
    });
  }

  dateToIsraelTz(date: Date): Date {
    const israelTimezone = 'Asia/Jerusalem';
    return utcToZonedTime(date, israelTimezone);
  }

  israelDateStringTz(date: Date): string {
    const israelTimezone = 'Asia/Jerusalem';
    return formatTz(date, 'yyyy-MM-dd HH:mm:ss', { timeZone: israelTimezone });
  }

  israelToLocalDate(date: Date): Date {
    const israelTimezone = 'Asia/Jerusalem';
    return zonedTimeToUtc(date, israelTimezone);
    // return zonedTimeToUtc(utc, Intl.DateTimeFormat().resolvedOptions().timeZone);
  }
}
