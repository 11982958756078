<div
  *ngIf="!skeleton"
  class="card text-center"
  [attr.aria-expanded]="!isCollapsed"
>
  <div
    class="card-header d-flex justify-content-between align-content-center d-lg-none" (click)="collapse.toggle()"
  >
    <div *ngIf="(user$ | async) as user">
      <p *ngIf="user.permission === 'client'" class="text-start card-text order-number"
         [routerLink]="['/my-orders/',order.id]">
        {{order.id}}
      <small class="d-block text-muted">Issue Date: {{order.createdAt|date:'shortDate'}}</small>
      </p>
      <p *ngIf="user.permission !== 'client'" class="text-start card-text order-number"
         [routerLink]="['/admin/order/form/',order.id]">
        {{order.id}}
      <small class="d-block text-muted">Issue Date: {{order.createdAt|date:'shortDate'}}</small>
      </p>

    </div>
    <div class="text-end">
      <p class="card-text order-number">
        {{order?.status || '-'}}
        <small class="d-block text-muted" *ngIf="order.deadLine">Deadline: {{order.deadLine|date:'shortDate'}}</small>
      </p>
    </div>
  </div>
  <div
    class="card-body pb-2 d-grid"
  >
    <!-- <h5 class="card-title">Card title</h5> -->
    <div *ngIf="(user$ | async) as user" class="d-none d-lg-inline">
      <h6 class="card-subtitle mb-2 text-muted">Order number</h6>
      <p *ngIf="user.permission === 'client'" class="card-text order-number"
         [routerLink]="['/my-orders/',order.id]">{{order.id}}</p>
      <p *ngIf="user.permission !== 'client'" class="card-text order-number"
         [routerLink]="['/admin/order/form/',order.id]">{{order.id}}</p>
    </div>
    <div class="d-none d-lg-inline">
      <h6 class="card-subtitle mb-2 text-muted">Issue Date</h6>
      <p class="card-text">{{order.createdAt|date:'shortDate'}}</p>
    </div>
    <div class="d-none d-lg-inline">
      <h6 class="card-subtitle mb-2 text-muted">Deadline</h6>
      <p class="card-text" *ngIf="order.deadLine">{{order.deadLine|date:'shortDate'}}</p>
      <p class="card-text" *ngIf="!order.deadLine">-</p>
    </div>
    <div class="d-none d-lg-inline">
      <h6 class="card-subtitle mb-2 text-muted">Status</h6>

      <p class="badge badge-status rounded-pill bg-primary">{{order?.status || '-'}}</p>
    </div>
    <div>
      <h6 class="card-subtitle mb-2 text-muted d-none d-lg-block">
        Download Photos
      </h6>
      <a [attr.href]="order.delivery" target="_blank" [ngClass]="{'disabled': !order.delivery}"
         class="btn btn-sm btn-black">Download</a>
    </div>

    <div>
      <h6 class="card-subtitle mb-2 text-muted d-none d-lg-block">Price Quote</h6>
      <button class="d-lg-none btn btn-sm btn-outline-black" [ngClass]="{'disabled': !order.priceQuote}"
              (click)="downloadPriceQuote(order)">Price Quote
      </button>
      <button class="d-none d-lg-block btn-invoice mx-auto" [ngClass]="{'canDownload': order.priceQuote}"
              (click)="downloadPriceQuote(order)"></button>
    </div>


    <div>
      <h6 class="card-subtitle mb-2 text-muted d-none d-lg-block">Invoice</h6>
      <button class="d-lg-none btn btn-sm btn-outline-black" [ngClass]="{'disabled': !order.invoice}"
              (click)="downloadInvoice(order)">Invoice
      </button>
      <button class="d-none d-lg-block btn-invoice mx-auto" [ngClass]="{'canDownload': order.invoice}"
              (click)="downloadInvoice(order)"></button>
    </div>

    <div>
      <h6 class="card-subtitle mb-2 text-muted d-none d-lg-block">
        Details
      </h6>
      <button class="btn btn-outline-dark pe-0 btn-sm d-flex mx-auto" (click)="collapse.toggle()">
        Details
        <span class="material-icons">
          {{isCollapsed ? 'expand_more' : 'expand_less'}}
        </span>
      </button>
    </div>
  </div>

  <div
    class="p-3 pt-0 text-start"
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed"
  >
    <hr/>
    <div class="d-flex justify-content-between pb-3">
      <h2 class="fs-4 d-none d-lg-inline">
        Resume order <small class="text-primary">{{order.id}}</small>
      </h2>
      <h2 class="fs-4 d-lg-none">Resume</h2>
    </div>

    <div class="row gx-2">
      <div class="col-12">
        <div class="information">
          <small>Pick a Service</small>
          <p>{{order.service}}</p>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="information">
          <small>Quantity of Items Shipped</small>
          <p>{{order.quantityItemShipped}}</p>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="information">
          <small>Num Of Photos Per Product</small>
          <p>{{order.quantityPhotosPerProduct}}</p>
        </div>
      </div>
      <div class="col-12">
        <div class="information">
          <small>Select Studio - Type of Photo</small>
          <p>{{order.typeOfPhotos | arrayToString}}</p>
        </div>
      </div>
      <div class="col-12">
        <div class="information">
          <small>Type of Product</small>
          <p>{{order.typeOfProducts | arrayToString}}</p>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="information">
          <small>Image Dimensions</small>
          <p>{{order.imageDimension}}</p>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="information">
          <small> Color Background & Shadow</small>
          <p>{{order.colorBackgroundAndShadow}}</p>
        </div>
      </div>
      <div class="col-12">
        <div class="information">
          <small>Extras</small>
          <p>{{(order.extras.type ?? '-') | arrayToString}}</p>
        </div>
      </div>
      <div class="col-12">
        <div class="information">
          <small class="d-block mb-2">References</small>
          <div class="d-flex flex-nowrap gap-2">
            <app-media *ngFor="let image of order.extras.images" [link]="image.pictureUrl"></app-media>
          </div>
          <p></p>
        </div>
      </div>
      <div class="col-12">
        <div class="information">
          <small>Briefing</small>
          <p>{{order.extras.briefing ?? '-'}}</p>
        </div>
      </div>
      <div class="col-12">
        <div class="information">
          <small>Link</small>
          <p>{{order.extras.link ?? '-'}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="skeleton"
  class="card text-center"
>
  <div
    class="card-header d-flex justify-content-between align-content-center d-lg-none"
  >
    <div>
      <p class="card-text order-number fs-4">
        <ngx-skeleton-loader [theme]="{height:'35px', width:'100px'}"></ngx-skeleton-loader>
      </p>
    </div>
    <div class="">
      <h6 class="card-subtitle text-muted">
        <ngx-skeleton-loader [theme]="{height:'20px', width:'50px'}"></ngx-skeleton-loader>
      </h6>
      <p class="card-text text-bottom text-primary">
        <ngx-skeleton-loader [theme]="{height:'20px', width:'50px'}"></ngx-skeleton-loader>
      </p>
    </div>
  </div>
  <div
    class="card-body pb-2 d-flex justify-content-between align-content-center"
  >
    <div class="d-none d-lg-inline">
      <h6 class="card-subtitle mb-2 text-muted">Order number</h6>
      <p class="card-text order-number">
        <ngx-skeleton-loader [theme]="{height:'35px', width:'100px'}"></ngx-skeleton-loader>
      </p>
    </div>
    <div class="d-none d-lg-inline">
      <h6 class="card-subtitle mb-2 text-muted">Date</h6>
      <p class="card-text">
        <ngx-skeleton-loader [theme]="{height:'20px', width:'50px'}"></ngx-skeleton-loader>
      </p>
    </div>
    <div class="d-none d-lg-inline">
      <h6 class="card-subtitle mb-2 text-muted">Status</h6>
      <p class="card-text p-2">
        <ngx-skeleton-loader [theme]="{height:'20px', width:'50px'}"></ngx-skeleton-loader>
      </p>
    </div>
    <div>
      <h6 class="card-subtitle mb-2 text-muted d-none d-lg-flex">
        Download Photos
      </h6>

      <button type="button" class="btn btn-sm btn-black" disabled>Download</button>
    </div>
    <div>
      <h6 class="card-subtitle mb-2 text-muted d-none d-lg-flex">Invoice</h6>
      <button class="d-lg-none btn btn-sm btn-outline-black ">Invoice</button>
      <button class="d-none d-lg-flex btn-invoice "></button>
    </div>
    <div>
      <h6 class="card-subtitle mb-2 text-muted d-none d-lg-flex">
        Save My Preference
      </h6>
      <button class="btn-preference" disabled [ngClass]="{'active':isFavorite}">

      </button>
    </div>
    <div>
      <h6 class="card-subtitle mb-2 text-muted d-none d-lg-flex">
        Details
      </h6>
      <button class="btn btn-link" disabled>
        Details
      </button>
    </div>
  </div>

</div>

