import { Component, OnInit } from '@angular/core';
import { FacebookLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { AuthService } from '../../services/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { State } from '../../models/state';
import { ToastrService } from 'ngx-toastr';
@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  next = '';
  public state: State = State.INITIAL;
  public State = State;
  public fgLogin = this.formBuilder.group({
    email: ['', [Validators.email, Validators.required]],
    password: ['', [Validators.email, Validators.required]]
  });

  constructor(
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apollo: Apollo,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(first()).subscribe(params => {
      this.next = params?.next ?? '/';
    });
    this.authService.user$
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        if (user?.permission === 'admin' || user?.permission === 'studio') {
          return this.router.navigate(['/admin/order/list']);
        }
        if (user?.checked) {
          return this.router.navigate([this.next]);
        }
        if (user) {
          return this.router.navigate(['/my-profile'], { queryParams: { next: this.next } });
        }
        return null;
      });

  }

  signInWithFB(): void {
    this.state = State.LOADING;
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).finally(() => {
      this.state = State.LOADED;
    });
  }

  loginWithSystem(): void {
    console.log(this.fgLogin)
    console.log(`${this.fgLogin.get('email')?.value}`)
    console.log(`${this.fgLogin.get('password')?.value}`)
    if (!this.fgLogin.get('email')?.value || !this.fgLogin.get('password')?.value) {
      this.toastrService.error(`Incorrect username and/or password<br>Don't have an account?<br> <a href='/auth/register?next=%2F'>Click to create account</a>`, '', {
        enableHtml: true
      }
    );
    }

    this.state = State.LOADING;
    this.authService.loginWithSystem({
      email: `${this.fgLogin.get('email')?.value}`,
      password: `${this.fgLogin.get('password')?.value}`,
    }).subscribe((user) => {
      this.state = State.LOADED;
      if (this.fgLogin.get('email')?.value && this.fgLogin.get('password')?.value) {
        console.log(user)
        if (!user) {
          this.toastrService.error(`Incorrect username and/or password<br>Don't have an account?<br> <a href='/auth/register?next=%2F'>Click to create account</a>`, '', {
                enableHtml: true
              }
            );
          console.log('no user found')
        }
      }
    });
  }
}
