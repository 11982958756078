<div class="container min-vh-100 d-flex flex-column justify-content-center">
  <div class="row d-flex flex-column justify-content-center align-content-center">
    <div class="col-12 col-lg-6 col-xl-6">
      <div class="card rounded-3">
        <div class="card-body">
          <div class="text-center mb-4">
            <img class="brand" src="../../../assets/images/logo-eshoots.png" alt="logo"/>
            <h2 class="mt-1 pb-1">Sign In</h2>
          </div>
          <!-- class="d-grid gap-2 col-12 col-md-8 col-xl-6 mx-auto" -->
          <div class="d-flex flex-column align-content-center justify-content-center gap-2 mx-auto login-container">
            <asl-google-signin-button type="standard" size="large" width="300px" locale="en_US">
            </asl-google-signin-button>

            <button
              class="btn btn-login btn-login-facebook btn-outline-light d-flex align-content-center justify-content-center mb-2"
              (click)="signInWithFB()">
              Sign in with Facebook
            </button>
            <button
              class="d-none btn btn-login btn-login-access-key btn-outline-light d-flex align-content-center justify-content-center mb-2">
              Receive access key by email
            </button>
          </div>

          <p class="divider-or text-center my-3">or</p>

          <form class="col-12 col-md-8 col-xl-8 mx-auto" (ngSubmit)="loginWithSystem()" [formGroup]="fgLogin">
            <div class="mb-3">
              <input type="email" placeholder="Email" class="form-control" formControlName="email"/>
            </div>
            <div class="mb-1">
              <input type="password" placeholder="Password" class="form-control" formControlName="password"/>
            </div>
            <div class="text-end mb-3 link-forgot">
              <a  [routerLink]="['/auth/forgot']">forgot password?</a>
            </div>
            <button type="submit" class="btn btn-black w-100" [disabled]="state === State.LOADING">{{state === State.LOADING ? 'logging in' : 'login'}}</button>
          </form>
          <div class="col-12 mx-auto my-5 text-center create-account">
            <p>Don't have an account?</p>
            <a [routerLink]="['/auth/register']" [queryParams]="{next}">Create account</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
