import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {debounceTime, map} from 'rxjs';


@UntilDestroy()
@Component({
  selector: 'app-choose-card',
  templateUrl: './choose-card.component.html',
  styleUrls: ['./choose-card.component.scss']
})

export class ChooseCardComponent implements OnInit {
  @Input() ref: string;
  @Input() indexRef: number;
  @Input() multiple: boolean;
  @Input() imgSrc: string;
  @Input() title: string;
  @Input() text: string;
  @Input() description = false;
  @Input() alignRef: string;
  @Input() form: UntypedFormGroup;
  @Output() emitControlOnChange: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.toggleSelectionWhenDescriptionChanges();
  }

  controlChange(e: any): void {
    this.emitControlOnChange.emit(e);
  }

  private toggleSelectionWhenDescriptionChanges(): void {
    if (!this.description) {
      return;
    }
    this.form.get('description')?.valueChanges.pipe(
      debounceTime(300),
      untilDestroyed(this),
      map(value => !!value?.length),
    ).subscribe(hasValue => {
      this.form.get(this.ref)?.patchValue(hasValue);
    });
  }
}
