import {Component, Input, OnChanges} from '@angular/core';
import {BehaviorSubject, map} from 'rxjs';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss']
})
export class MediaPreviewComponent implements OnChanges {

  @Input() file: File | Blob;

  private safeLink$ = new BehaviorSubject<File | Blob | null>(null);
  dataUrl$ = this.safeLink$.pipe(map((url) => {
    return this.loadImage(url);
  }));

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnChanges(): void {
    this.safeLink$.next(this.file);
  }

  private loadImage(file: File | Blob | null): { link: SafeResourceUrl; type: string; contentType: string } | null {
    if (!file) {
      return null;
    }
    let type = '';
    switch (true) {
      case file.type.startsWith('image/'):
        type = 'image';
        break;

      case file.type.startsWith('video/'):
        type = 'video';
        break;
    }


    return {
      link: this.sanitizer.bypassSecurityTrustResourceUrl(
        URL.createObjectURL(file)
      ),
      contentType: file.type,
      type,
    };
  }

}
