import {NgSelectModule} from '@ng-select/ng-select';
import {ValidationMessageComponent} from './components/validation-message/validation-message.component';
import {RouterModule} from '@angular/router';
import {PipeModule} from './../pipes/pipe.module';
import {OrderItemComponent} from './../shared/components/order-item/order-item.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MainActionButtonComponent} from './components/main-action-button/main-action-button.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProfileFormComponent} from './components/profile-form/profile-form.component';
import {PrimaryButtonComponent} from './components/primary-button/primary-button.component';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {NgbCollapseModule, NgbPaginationModule,} from '@ng-bootstrap/ng-bootstrap';
import {ClientItemComponent} from './components/client-item/client-item.component';
import {BackButtonDirective} from './directives/back-button.directive';
import {MediaComponent} from './components/media/media.component';
import {MediaPreviewComponent} from './components/media-preview/media-preview.component';
import {ValidationMessagePipe} from './pipes/validation-message.pipe';
import {OrderFormComponent} from './components/order-form/order-form.component';
import {InputTelComponent} from './forms/input-tel.component';
import {PhoneFormatterPipe} from './pipes/phone-formatter.pipe';

@NgModule({
  declarations: [
    MainActionButtonComponent,
    ProfileFormComponent,
    PrimaryButtonComponent,
    OrderItemComponent,
    ClientItemComponent,
    BackButtonDirective,
    MediaComponent,
    MediaPreviewComponent,
    ValidationMessageComponent,
    ValidationMessagePipe,
    OrderFormComponent,
    InputTelComponent,
    PhoneFormatterPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule.forRoot({}),
    PipeModule,
    NgbCollapseModule,
    RouterModule,
    NgbPaginationModule,
    NgSelectModule,
  ],
  exports: [
    MainActionButtonComponent,
    ProfileFormComponent,
    PrimaryButtonComponent,
    OrderItemComponent,
    ClientItemComponent,
    BackButtonDirective,
    MediaComponent,
    MediaPreviewComponent,
    ValidationMessageComponent,
    OrderFormComponent,
    InputTelComponent,
    PhoneFormatterPipe
  ],
})
export class SharedModule {
}
