import {ForgotComponent} from './pages/forgot/forgot.component';
import {PipeModule} from './pipes/pipe.module';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';

import {NgbCollapseModule, NgbDropdownModule, NgbModule,} from '@ng-bootstrap/ng-bootstrap';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {UiModule} from './ui/ui.module';
import {ChatComponent} from './component/chat/chat.component';
import {HowItWorksComponent} from './pages/how-it-works/how-it-works.component';
import {HowItWorksCardComponent} from './component/how-it-works-card/how-it-works-card.component';


import {ChooseCardComponent} from './component/choose-card/choose-card.component';
import {HomeCardComponent} from './component/home-card/home-card.component';
import {HomeComponent} from './pages/home/home.component';
import {OrderComponent} from './pages/order/order.component';
import {LoginComponent} from './pages/login/login.component';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule,} from '@abacritt/angularx-social-login';
import {RegisterComponent} from './pages/register/register.component';
import {ContactComponent} from './pages/contact/contact.component';
import {MyOrdersComponent} from './pages/my-orders/my-orders.component';
import {GraphQLModule} from './graphql.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ApolloInterceptor} from './apollo.interceptor';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {ReviewOrderComponent} from './pages/review-order/review-order.component';

import {SharedModule} from './shared/shared.module';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';

import { environment } from '../environments/environment'


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeCardComponent,
    OrderComponent,
    ChooseCardComponent,
    LoginComponent,
    RegisterComponent,
    ContactComponent,
    MyOrdersComponent,
    ChatComponent,
    HowItWorksComponent,
    HowItWorksCardComponent,
    ReviewOrderComponent,
    ForgotComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    NgbDropdownModule,
    UiModule,
    SocialLoginModule,
    NgbCollapseModule,
    GraphQLModule,
    HttpClientModule,
    PipeModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({preventDuplicates: true}),
    NgxSkeletonLoaderModule.forRoot({}),
    FormsModule,
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApolloInterceptor,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.google_oauth_cliendID,
              {
                oneTapEnabled: false
              }
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebook_clientID),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],


  bootstrap: [AppComponent],
})
export class AppModule {
}
