<a class="card h-100">
  <figure class="mb-1">
    <img [routerLink]="['/order']" [src]="feature.frontImage" class="card-img front-image img-fluid"/>
    <img [routerLink]="['/order']" [src]="feature.backImage" class="card-img back-image img-fluid"/>
  </figure>
  <h3 class="card-title text-black  mb-4">{{ feature.title }}</h3>
  <p class="card-text text-gray mb-4">{{ feature.text.substring(0, 303) }}{{isOpened || feature.title == 'product photos' ? '' : '...'}}{{remainingText}}<span [id]="feature.title" class="read-more" (click)="readMoreToggle(feature.text)"><br>{{isOpened ? 'show less.' : 'read more'}}</span></p>

  <img [routerLink]="['/order']" src="assets/images/icons/arrow.svg" class="d-block ms-auto mt-auto go-arrow"/>
</a>
