import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AppService} from '../../services/app.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
  formContact: FormGroup;
  message = '';
  isLoading = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private appService: AppService) {
    this.formContact = this.formBuilder.group({
      fullName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required, Validators.minLength(10)]],
    });
  }


  onSubmit(): void {
    this.formContact.markAllAsTouched();
    if (!this.formContact.valid) {
      return;
    }
    const formValue = this.formContact.value;
    this.isLoading = true;
    this.appService.contact({
      fullName: formValue.fullName,
      phoneNumber: formValue.phoneNumber,
      email: formValue.email,
      message: formValue.message,
    }).subscribe(message => {
      this.isLoading = false;
      if (!message) {
        return;
      }
      this.message = message;
      this.formContact.reset();
    });
  }
}
