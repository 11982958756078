<div>
  <form class="row" [formGroup]="formRegister" (ngSubmit)="onSubmit()">
    <div class="col-12 col-lg-6 offset-lg-3 col-xl-6">
      <div
        class="d-flex flex-column justify-content-center align-content-center"
      >
        <input
          type="file"
          class="d-none"
          name="thumbnail"
          id="thumbnail"
          (change)="onFileSelected($event)"
        />
        <label
          class="d-flex flex-column justify-content-center align-content-center"
          for="thumbnail"
        >
          <div class="align-self-center">
            <img
              [src]="
                (profilePicture | authenticatedUrl | async) ??
                '/assets/images/upload-selfie.png'
              "
              class="profile-picture"
              alt=""
            />
          </div>
          <span class="text-center">Upload a selfie</span>
        </label>
      </div>

      <div class="card rounded-3 mb-4">
        <h6 class="pt-3 ps-3">Personal Info</h6>
        <div class="card-body">
          <div class="mb-3">
            <input
              type="text"
              class="form-control icon icon-user"
              placeholder="full name"
              formControlName="fullName"
            />
          </div>
          <div class="mb-3">
            <app-input-tel
              [entryControl]="formRegister.controls['phoneNumber']"
              [countryControl]="formRegister.controls['countryCode']"
              ormControlName="phoneNumber"
            ></app-input-tel>
          </div>
          <div class="mb-3">
            <input
              type="email"
              class="form-control icon icon-email"
              placeholder="email"
              formControlName="email"
            />
            <i></i>
          </div>
          <div class="mb-3 input-password" *ngIf="!update">
            <input
              type="password"
              class="form-control"
              placeholder="create a password"
              #password
              formControlName="password"
            />
            <i
              class="input-password__icon"
              (click)="togglePassword(password)"
            ></i>
          </div>
          <div class="mb-3 input-password" *ngIf="!update">
            <input
              type="password"
              class="form-control"
              placeholder="confirm password"
              #passwordConfirm
              formControlName="confirmPassword"
            />
            <i
              class="input-password__icon"
              (click)="togglePassword(passwordConfirm)"
            ></i>
          </div>
        </div>
      </div>

      <div class="card rounded-3 mb-2">
        <h6 class="pt-3 ps-3">Billing information</h6>
        <div class="card-body row">
          <div class="mb-3 col-12 col-lg-6">
            <input
              type="text"
              class="form-control"
              placeholder="Company Name"
              formControlName="zipCode"
            />
          </div>
          <div class="mb-3 col-12 col-lg-6">
            <input
              type="text"
              class="form-control"
              placeholder="Tax ID"
              formControlName="taxID"
            />
          </div>

          <div class="mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Receipt Name"
              formControlName="city"
            />
          </div>
          <div class="mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Street Address"
              formControlName="streetAddress"
            />
          </div>
          <div class="mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Country"
              formControlName="country"
            />
            <i></i>
          </div>
        </div>
      </div>

      <div class="card rounded-3 mb-2" [ngClass]="{ 'd-none': !isAdmin }">
        <h6 class="pt-3 ps-3">Preferences Client</h6>
        <div class="card-body row">
          <div class="mb-3 col-12 col-lg-12">
            <textarea class="form-control" style="height: 100px"></textarea>
          </div>
        </div>
      </div>
      <div class="">
        <div class="mb-3 form-check" [ngClass]="{ 'd-none': isAdmin }">
          <input
            type="checkbox"
            class="form-check-input d-none"
            id="checkAgreeMessage"
            formControlName="notify"
          />
          <label
            class="form-check-label icon icon-check"
            for="checkAgreeMessage"
            >yes, I agree to receive messages from eShoots</label
          >
        </div>

        <div class="text-center mt-5 mb-5">
          <button
            *ngIf="update"
            type="submit"
            class="btn btn-lg btn-black btn-next w-50"
            [disabled]="state === State.LOADING"
          >
            {{ state === State.LOADING ? "updating" : "update" }}
          </button>

          <button
            *ngIf="!update"
            type="submit"
            class="btn btn-lg btn-black btn-next w-50"
            [disabled]="state === State.LOADING"
          >
            {{ state === State.LOADING ? "registering" : "register" }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
