import {OrderType} from './../../models/order.model';
import {Observable} from 'rxjs';
import {OrderService} from './../../services/order.service';
import {Component} from '@angular/core';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss'],
})
export class MyOrdersComponent {
  orders$: Observable<OrderType[]>;

  constructor(private orderService: OrderService) {
    this.orders$ = this.orderService.myOrders();
  }

}
