import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {catchError, of} from 'rxjs';
import {matchOtherValidator} from 'src/app/validators/match-other.validator';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  message = '';
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      token: [''],
      newPassword: ['', [Validators.required]],
      confirmPassword: [
        '',
        [
          Validators.required,
          matchOtherValidator('newPassword', 'Password', 'Confirm Password'),
        ],
      ],
    });
  }

  ngOnInit(): void {
    const token = this.activatedRoute.snapshot.queryParamMap.get('token');

    if (!token) {
      this.toastr.error('Invalid Link');
      this.router.navigate(['/']);
    }
    this.form.get('token')?.setValue(token);
  }

  resetPassword(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.authService
      .resetPassword(
        this.form.get('token')?.value,
        this.form.get('newPassword')?.value
      )
      .pipe(catchError((err) => of(null)))
      .subscribe((result) => {
        this.isLoading = false;
        if (result) {
          this.message = result;
        }
      });
  }

  togglePassword(password: HTMLElement): void {
    const type =
      password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);
  }
}
