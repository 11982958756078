import {ArrayToStringPipe} from './array-to-string.pipe';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthenticatedUrlPipe} from '../shared/pipes/authenticated-file.pipe';


@NgModule({
  declarations: [ArrayToStringPipe, AuthenticatedUrlPipe],
  imports: [
    CommonModule
  ],
  exports: [ArrayToStringPipe, AuthenticatedUrlPipe]
})
export class PipeModule {
}
