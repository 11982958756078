import { Observable } from 'rxjs';
import { AuthService, User } from './../../../services/auth.service';
import { AppService } from './../../../services/app.service';
import { OrderType } from '../../../models/order.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss'],
})
export class OrderItemComponent {
  public isCollapsed = true;
  isFavorite = false;
  buttonBackground = 'assets/images/icons/';
  clickedBackground = 'assets/button-clicked.png';
  @Input() order: OrderType;
  @Input() skeleton = false;
  user$: Observable<User | null>;

  constructor(
    private appService: AppService,
    private authService: AuthService
  ) {
    this.user$ = this.authService.user$;
  }

  downloadPriceQuote(order: OrderType) {
    if (typeof order.priceQuote == 'string') {
      this.appService.download(order.priceQuote);
    }
  }

  downloadInvoice(order: OrderType) {
    if (typeof order.invoice == 'string') {
      this.appService.download(order.invoice);
    }
  }

  toggleFavorite(event: MouseEvent) {
    event.stopPropagation();
    this.isFavorite = !this.isFavorite;
  }
}
