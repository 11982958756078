<section class="py-5">
  <article class="container">
    <h2 class="text-black  mb-4">
      eComm photos & fashion visual content
    </h2>
    <div class="row mb-5">
      <div class="col-12 col-md-6 col-lg-4 mb-4" *ngFor="let feature of features">
        <app-home-card [feature]="feature">
        </app-home-card>
      </div>
    </div>
    <hr class="my-5" />
    <a href="https://calendly.com/eshoots" target="_blank" style="text-decoration: none;">
      <div class="studio-day-row" [routerLink]="['https://calendly.com/eshoots']">
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <a class="card h-100">
            <figure class="mb-1">
              <img src="../assets/images/studio-1.png"
                class="card-img front-image img-fluid" />
              <img src="../assets/images/studio-1.png"
                class="card-img back-image img-fluid" />
            </figure>
          </a>
        </div>
        <div class="studio-text">
          <h1 class="text-center text-black">
            Book DIY Studio Time
          </h1>
          <p class="text-gray">
            Rent our high quality equipment by the hour, and get all the photographed content.
          </p>
          <a href="https://calendly.com/eshoots" target="_blank" class="btn btn-lg btn-outline-black ps-3">
            Rent Studio
          </a>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-4">
          <a class="card h-100">
            <figure class="mb-1">
              <img src="../assets/images/studio-2.png"
                class="card-img front-image img-fluid" />
              <img src="../assets/images/studio-2.png"
                class="card-img back-image img-fluid" />
            </figure>
          </a>
        </div>
      </div>
    </a>
    <hr class="my-5" />

    <h3 class="text-gray">
      Clients
    </h3>
    <div class="pb-5">
      <div class="slider">
        <div class="slide-track">
          <div class="slide" *ngFor="let item of gallery">
            <img [src]="item.img_src" />
          </div>
          <div class="slide" *ngFor="let item of gallery">
            <img [src]="item.img_src" />
          </div>
        </div>
      </div>
    </div>
    <hr class="my-5" />
    <h4 class="text-gray text-center pt-5 mb-3">
      Follow eShoots
      <a class="instagram-link d-block mt-2" target="_blank"
        href="https://www.instagram.com/eshoots.studio/">@eshoots.studio</a>
    </h4>
    <div class="instagram">
      <div class="row gx-3">
        <div class="col-12 col-md mb-3">
          <img src="https://www2.e-shoots.com/images/instagram/snapshot/1" class="img-fluid rounded"
            alt="Instagram Picture" />
        </div>
        <div class="col-12 col-md mb-3">
          <img src="https://www2.e-shoots.com/images/instagram/snapshot/2" class="img-fluid rounded"
            alt="Instagram Picture" />
        </div>
        <div class="col-12 col-md">
          <img src="https://www2.e-shoots.com/images/instagram/snapshot/3" class="img-fluid rounded"
            alt="Instagram Picture" />
        </div>
      </div>
    </div>
  </article>

  <app-chat></app-chat>
</section>