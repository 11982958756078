import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'arrayToString'})
export class ArrayToStringPipe implements PipeTransform {
  transform(
    value: Array<any> | string
  ): string {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      return value;
    }

    if (!Array.isArray(value)) {
      throw Error(`Value must be an array now it is  ${JSON.stringify(value)}`);
    }

    if (!value.length) {
      return '';
    }

    return value.join(', ');
  }
}
