import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './../app-routing.module';

import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BlankLayoutComponent } from './blank-layout/blank-layout.component';
import { ValidationMessageComponent } from '../shared/components/validation-message/validation-message.component';

import {ValidationMessagePipe} from '../shared/pipes/validation-message.pipe';

@NgModule({

  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    BlankLayoutComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    NgbDropdownModule
  ],
  exports: [
    LayoutComponent
  ]
})
export class UiModule { }
