import {OrderInputType} from '../../models/order.model';
import {ApiService} from '../../services/api.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {atLeastOneSelectedValidator} from 'src/app/validators/at-least-one-selected.validator';
import {Router} from '@angular/router';
import {OrderService} from '../../services/order.service';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../services/auth.service';
import {first} from 'rxjs/operators';
import {State} from '../../models/state';
import {FileFormatValidator} from 'src/app/validators/file-format.validator';
import {FileSizeValidator} from 'src/app/validators/file-size.validator';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit, OnDestroy {
  serviceOptions$ = this.serviceApi.getServicesOptions();
  dimensions$ = this.serviceApi.getDimensionOptions();
  formService!: FormGroup;
  formItemsQuantity!: FormGroup;
  formSelectPhotos!: FormGroup;
  formSelectProduct!: FormGroup;
  formImageDimension!: FormGroup;
  formBackground!: FormGroup;
  formExtras!: FormGroup;
  formReference!: FormGroup;

  steps: { form: FormGroup; title: string }[] = [];
  activeStepIndex = 0;
  dashOffset: string;
  fileName: string;
  inputFiles = [];
  state = State.INITIAL;
  State = State;

  constructor(
    private formBuilder: FormBuilder,
    private serviceApi: ApiService,
    private router: Router,
    private orderService: OrderService,
    private toastrService: ToastrService,
    private authService: AuthService
  ) {
    this.initFormService();
    this.initFormQuantity();
    this.initFormSelectPhotos();
    this.initFormSelectProduct();
    this.initFormImageDimension();
    this.initFormBackground();
    this.initFormExtras();
    this.initFormReference();
  }

  get photosTypeArray(): FormGroup[] {
    const formArray = this.formSelectPhotos.get('photosType') as FormArray;
    return formArray.controls as FormGroup[];
  }

  get productsTypeArray(): FormGroup[] {
    const formArray = this.formSelectProduct.get('productsType') as FormArray;
    return formArray.controls as FormGroup[];
  }

  get dimensionsTypeArray(): FormGroup[] {
    const formArray = this.formImageDimension.get('dimensionsType') as FormArray;
    return formArray.controls as FormGroup[];
  }

  get backgroundsTypeArray(): FormGroup[] {
    const formArray = this.formBackground.get('backgroundsType') as FormArray;
    return formArray.controls as FormGroup[];
  }

  get extrasTypeArray(): FormGroup[] {
    const formArray = this.formExtras.get('extrasType') as FormArray;
    return formArray.controls as FormGroup[];
  }

  get referenceFile() {
    return this.formReference.controls['referenceFile'] as FormArray;
  }

  ngOnDestroy(): void {
    this.orderService.setOrderInProgress({
      formValues: this.getAllFormValues(),
      step: this.activeStepIndex,
    });
  }

  ngOnInit(): void {
    this.fillForms();
    this.onChangeFormImageDimension()
    this.onChangeFormSelectProduct();

    this.steps = [
      {
        form: this.formService,
        title: 'Pick a Service',
      },
      {
        form: this.formItemsQuantity,
        title: 'Quantity',
      },
      {
        form: this.formSelectPhotos,
        title: 'Type of Photo',
      },
      {
        form: this.formSelectProduct,
        title: 'Product Type',
      },
      {
        form: this.formImageDimension,
        title: 'Image Dimensions',
      },
      {
        form: this.formBackground,
        title: 'Backgrounds',
      },
      {
        form: this.formExtras,
        title: 'Extras',
      },
      {
        form: this.formReference,
        title: 'References',
      },
    ];
    this.calculateDashOffset();
    this.fillOrderInProgress();
  }

  nextStep(): void {
    console.log(this.steps[this.activeStepIndex].form)
    this.steps[this.activeStepIndex].form.markAllAsTouched();

    if (this.steps[this.activeStepIndex].form.invalid) {
      this.toastrService.error(
        'Please make sure all fields are filled in correctly'
      );
      return;
    }

    if (this.activeStepIndex >= this.steps.length) {
      return console.log('redirect');
    }

    this.activeStepIndex++;
    this.calculateDashOffset();
    this.scrollToTop();
  }

  backStep(): void {
    this.activeStepIndex--;
    this.calculateDashOffset();
    this.scrollToTop();
  }

  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  calculateDashOffset(): void {
    this.dashOffset =
      285 - (285 / this.steps.length) * (this.activeStepIndex + 1) + 'px';
  }

  onFileSelected(event: any): void {
    const formReferenceFile = this.formReference.get(
      'referenceFile'
    ) as FormArray;
    Array.from(event.target.files).forEach((file) => {
      formReferenceFile?.push(
        this.formBuilder.group({
          file: [
            file,
            [
              FileFormatValidator([
                'image/jpeg',
                'image/png',
                'video/mp4',
                'image/jpg',
              ]),
              FileSizeValidator(),
            ],
          ],
        })
      );
    });
    formReferenceFile.markAllAsTouched();
  }

  removerItem(item: any) {
    this.referenceFile.removeAt(item);
  }

  onSubmit(): void {
    const order = this.getOrderTypeFromFormValue();
    this.orderService.stashOrder(order);

    this.authService.user$.pipe(first()).subscribe((user) => {
      if (user?.checked) {
        return this.router.navigate(['review']);
      }
      if (user) {
        return this.router.navigate(['my-profile'], {
          queryParams: {next: 'review'},
        });
      }
      return this.router.navigate(['auth/login'], {
        queryParams: {next: 'review'},
      });
    });
  }

  getSelectedItems(items: FormGroup[]): string[] {
    return items
      .filter((item) => {
        return item.value.selected;
      })
      .map((item) => {
        return item.value.description || item.value.type.title;
      });
  }

  getOrderTypeFromFormValue(): OrderInputType {
    const service = this.formService.get('service')?.value;

    const typeOfProducts = this.getSelectedItems(this.productsTypeArray);
    if (typeOfProducts.includes('Other')) {
      typeOfProducts.push(
        this.formSelectProduct.get('othersProduct')?.value ?? ''
      );
    }
    const typeOfPhotos = this.getSelectedItems(this.photosTypeArray);
    const imageDimension =
      this.formImageDimension.get('dimensionsType')?.value === 'Other'
        ? this.formImageDimension.get('othersDimension')?.value
        : this.formImageDimension.get('dimensionsType')?.value;
        console.log(this.steps[this.activeStepIndex].form.invalid)
    const colorBackgroundAndShadow = this.getSelectedItems(
      this.backgroundsTypeArray
    );
    const extrasType = this.getSelectedItems(this.extrasTypeArray);

    return {
      service,
      typeOfPhotos,
      typeOfProducts,
      imageDimension,
      colorBackgroundAndShadow,
      quantityItemShipped: this.formItemsQuantity.get('itemsQuantity')?.value,
      quantityPhotosPerProduct:
      this.formItemsQuantity.get('photosPerProduct')?.value,
      extras: {
        type: extrasType?.[0] ?? null,
        briefing: this.formReference.get('briefing')?.value ?? null,
        link: this.formReference.get('referenceLink')?.value ?? null,
        images: this.referenceFile.controls.map(control => control.value?.file)
      },
    };
  }


  private initFormService(): void {
    this.formService = this.formBuilder.group({
      service: ['', Validators.required],
    });
  }

  private initFormQuantity(): void {
    this.formItemsQuantity = this.formBuilder.group({
      itemsQuantity: ['', Validators.required],
      photosPerProduct: ['', Validators.required],
    });
  }

  private initFormSelectPhotos(): void {
    this.formSelectPhotos = this.formBuilder.group({
      photosType: this.formBuilder.array([], [atLeastOneSelectedValidator(1)]),
    });
  }

  private fillFormSelectPhotos(): void {
    const formArrayPhotos = this.formSelectPhotos.get(
      'photosType'
    ) as FormArray;

    this.serviceApi.getPhotosOptions().subscribe((options) => {
      options.forEach((type) => {
        formArrayPhotos.push(
          this.formBuilder.group({
            selected: [false],
            type: [type],
          })
        );
      });
    });
  }

  private initFormSelectProduct(): void {
    this.formSelectProduct = this.formBuilder.group({
      productsType: this.formBuilder.array([], [atLeastOneSelectedValidator()]),
      othersProduct: ['']
    });
  }

  private initFormImageDimension(): void {
    this.formImageDimension = this.formBuilder.group({
      dimensionsType: ['', Validators.required],
      othersDimension: [''],
    });
  }

  private onChangeFormSelectProduct(): void {
    this.formSelectProduct
      .get('othersProduct')
      ?.valueChanges.subscribe((value) => {
      const indexOther = this.productsTypeArray.findIndex((type) => {
        return type.value.type === 'Other';
      });
      const formArray = this.formSelectProduct.get(
        'productsType'
      ) as FormArray;

      formArray.at(indexOther).get('selected')?.setValue(!!value);
    });

    this.formImageDimension
      .get('dimensionsType')
      ?.valueChanges.subscribe((value) => {
      if (value !== 'Other') {
        this.formImageDimension.get('othersDimension')?.setValue('');
      }
    });

    this.formImageDimension
      .get('othersDimension')
      ?.valueChanges.subscribe((value) => {
      if (value.length) {
        this.formImageDimension.get('dimensionsType')?.setValue('Other');
      }
    });
  }

  private onChangeFormImageDimension(): void {

    this.formImageDimension
      .get('dimensionsType')
      ?.valueChanges.subscribe((value) => {
      if (value !== 'Other') {
        this.formImageDimension.get('othersDimension')?.setValue('');
      }
    });

    this.formImageDimension
      .get('othersDimension')
      ?.valueChanges.subscribe((value) => {
      if (value.length) {
        this.formImageDimension.get('dimensionsType')?.setValue('Other');
      }
    });
  }

  private fillformSelectProduct(): void {
    const formArrayProduct = this.formSelectProduct.get(
      'productsType'
    ) as FormArray;
    this.serviceApi.getProductOptions().subscribe((options) => {
      options.forEach((type) => {
        formArrayProduct.push(
          this.formBuilder.group({
            selected: [false],
            type: [type],
          })
        );
      });
    });
  }

  private initFormBackground(): void {
    this.formBackground = this.formBuilder.group({
      backgroundsType: this.formBuilder.array(
        [],
        [atLeastOneSelectedValidator(1)]
      ),
    });
  }

  private fillFormBackground(): void {
    const formArrayBackground = this.formBackground.get(
      'backgroundsType'
    ) as FormArray;

    this.serviceApi.getBackgroundOptions().subscribe((options) => {
      options.forEach((type) => {
        formArrayBackground.push(
          this.formBuilder.group({
            selected: [false],
            description: [''],
            type: [type],
          })
        );
      });
    });
  }

  private initFormExtras(): void {
    this.formExtras = this.formBuilder.group({
      extrasType: this.formBuilder.array([]),
    });
  }

  private fillFormExtras(): void {
    const formArrayExtras = this.formExtras.get('extrasType') as FormArray;
    this.serviceApi.getExtrasOptions().subscribe((options) => {
      options.forEach((type) => {
        formArrayExtras.push(
          this.formBuilder.group({
            selected: [false],
            type: [type],
          })
        );
      });
    });
  }

  private initFormReference(): void {
    this.formReference = this.formBuilder.group({
      referenceFile: this.formBuilder.array([]),

      referenceLink: [''],
      briefing: ['', Validators.required],
    });
  }


  private fillOrderInProgress(): void {
    const order = this.orderService.getOrderInProgress();
    if (!order) {
      return;
    }

    this.steps.forEach((step, index) => {
      step.form.patchValue(order.formValues[index]);
    });
    this.activeStepIndex = order.step;
  }

  private getAllFormValues(): any[] {
    return this.steps.map((step) => step.form?.value);
  }

  private fillForms(): void {
    this.fillFormSelectPhotos();
    this.fillformSelectProduct();
    this.fillFormBackground();
    this.fillFormExtras();
  }
}
