<tr>
  <td class="lh-lg d-none d-lg-table-cell">
    <a class="text-primary" [routerLink]="['/admin/client/form/'+ client.id]">{{client.name}}</a>
  </td>
  <td class="lh-lg">
    <a class="d-block d-lg-none text-primary text-name"
       [routerLink]="['/admin/client/form/'+ client.id]">{{client.name}}</a>
    <span class="d-block text-name">{{client.email}}</span>
    <span class="d-lg-none">{{client.phoneNumber}}</span>
  </td>
  <td class="lh-lg d-none d-lg-table-cell">
    <span class="">{{client.phoneNumber | phoneFormatter}}</span>
  </td>


  <td class="lh-lg ">
    <div class="d-flex justify-content-end">
      <button class="m-0 btn btn-outline-dark btn-sm d-flex align-items-center" (click)="collapse.toggle()">
        Orders
        <span class="material-icons">
          {{ isCollapsed ? "expand_more" : "expand_less" }}
        </span>
      </button>
    </div>
  </td>
  <td class="text-end">
    <a class="btn btn-sm btn-outline-black ms-auto" [routerLink]="'/admin/client/'+ client.id + '/order/form'">New
      Order</a>
  </td>
</tr>

<tr>
  <td colspan="5" class="p-0">
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <ng-container *ngIf="!isCollapsed">
        <app-order-item *ngFor="let order of clientOrders$ | async" [order]="order"
                        class="d-block mb-2"></app-order-item>
      </ng-container>
    </div>
  </td>
</tr>
