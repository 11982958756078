<div *ngIf="multiple; then checkbox else radio"></div>
<ng-template #checkbox>
  <label class="d-flex h-100" [for]="ref+'-'+indexRef" [formGroup]="form">
    <input type="checkbox" [formControlName]="ref" [name]="ref" [attr.name]="ref" [id]="ref+'-'+indexRef"
           [value]="title" (change)="controlChange(title)"/>
    <div class="card h-100 w-100" ngClass="{{alignRef}}">
      <div *ngIf="description; then textarea else image"></div>
      <ng-template #image>
        <img [src]="imgSrc" class="card-img mb-1"/>
      </ng-template>
      <ng-template #textarea>
        <textarea formControlName="description" placeholder="Describe..." [name]="ref+'Other'"
                  [attr.name]="ref+'Other'"></textarea>
      </ng-template>
      <h3 class="text-black text-wrap my-2">{{ title }}</h3>
      <p class="text-gray text-wrap  mb-2" *ngIf="text.length > 0">{{ text }}</p>
    </div>
  </label>
</ng-template>


<ng-template #radio>
  <label class="d-flex w-100 h-100" [for]="ref+'-'+indexRef" [formGroup]="form">
    <input type="radio" [name]="ref" [attr.name]="ref" [formControlName]="ref" [id]="ref+'-'+indexRef" [value]="title"/>
    <div class="card h-100 w-100" ngClass="{{alignRef}}">
      <img [src]="imgSrc" class="card-img mb-1"/>
      <h3 class="text-black  text-wrap my-2 mb-3">{{ title }}</h3>
      <p class="text-gray text-wrap mb-3" *ngIf="text.length > 0">{{ text }}</p>
    </div>
  </label>
</ng-template>
