<section class="content">
  <article class="container text-center">
    <ng-container>
      <div id="stepper-group" class="d-none d-lg-flex justify-content-center">
        <div *ngFor="let stepItem of steps; let i = index"
             class="stepper-item d-flex justify-content-center align-items-center">
          <span [ngClass]="{
              'stepper-check': stepItem?.form?.valid && activeStepIndex > i,
              'stepper-active': activeStepIndex === i
            }" class="d-flex justify-content-center align-items-center stepper-check">{{ i + 1 }}</span>
        </div>
      </div>

      <div id="stepper-mobile" class="d-flex justify-content-between d-lg-none">
        <div class="stepper-item d-flex justify-content-between align-items-center">
          <div class="stepper-item-circle d-flex align-self-center justify-content-center">
            <svg viewBox="0 0 100 100">
              <circle cx="50" cy="50" r="45"></circle>
              <circle cx="50" cy="50" r="45" [ngStyle]="{ 'stroke-dashoffset': dashOffset }"></circle>
            </svg>
            <div class="number d-flex justify-content-center align-items-center">
              <span class="stepper-item-count d-flex align-self-center justify-content-center">{{ activeStepIndex + 1 }}
                of {{ steps.length }}</span>
            </div>
          </div>

          <div class="stepper-item-content text-start">
            <h2 class="stepper-item-title fw-bold">
              {{ steps[activeStepIndex].title }}
            </h2>
            <p class="stepper-item-subtitle fw-bolder ">
              {{ steps[activeStepIndex].title }}
            </p>
            <span class="stepper-item-next fw-lighter" *ngIf="activeStepIndex < steps.length - 1">Next: {{
              steps[activeStepIndex + 1].title }}</span>
          </div>
        </div>
      </div>

      <form [formGroup]="formService" *ngIf="formService === steps[activeStepIndex].form">
        <div>
          <h2 class="text-black mt-4 mt-lg-0 mb-4">Pick a Service</h2>
          <app-validation-message [control]="formService.get('service')" label="Service"></app-validation-message>
          <div class="row justify-content-center mb-5">
            <div class="col-12 col-md-6 col-lg-4 mb-4" *ngFor="let service of serviceOptions$ | async; let i = index">
              <app-choose-card [form]="formService" ref="service" [indexRef]="i" [multiple]="false"
                               [imgSrc]="service.imgSrc" [title]="service.title" [text]="service.text"
                               alignRef="text-start">
              </app-choose-card>
            </div>
            <div class="rent-studio-text"> 
              <h2><a href="https://calendly.com/eshoots" target="_blank">Rent Studio choose date and time</a></h2>
            </div>
          </div>
        </div>
      </form>

      <form [formGroup]="formItemsQuantity" *ngIf="formItemsQuantity === steps[activeStepIndex].form">
        <div>
          <div class="row justify-content-evenly">
            <div class="col-12 mt-4 mb-4">
              <h2 class="text-black mt-4 mt-lg-0 mb-4">Quantity of Items Shipped</h2>
              <app-validation-message [control]="formItemsQuantity.get('itemsQuantity')"
                                      label="Quantity of Items Shipped"></app-validation-message>
              <textarea class="form-control" formControlName="itemsQuantity"
                        placeholder="Enter the text here..."></textarea>
            </div>
            <div class="col-12 mb-5">
              <h2 class="text-black mt-4 mt-lg-0 mb-4">Num of Photos Per Product</h2>
              <app-validation-message [control]="formItemsQuantity.get('photosPerProduct')" label="Number of Photos">
              </app-validation-message>
              <textarea class="form-control" formControlName="photosPerProduct"
                        placeholder="Enter the text here..."></textarea>
            </div>
          </div>
        </div>
      </form>

      <form [formGroup]="formSelectPhotos" *ngIf="formSelectPhotos === steps[activeStepIndex].form">
        <div>
          <h2 class="text-black mt-4 mt-lg-0 mb-4">
            Select Studio - Type of Photo (can select more than 1)
          </h2>
          <app-validation-message [control]="formSelectPhotos.get('photosType')" label="Type of Photo">
          </app-validation-message>
          <div class="row mb-5">
            <div class="col-12 col-md-6 col-lg-3 mb-4" formArrayName="photosType"
                 *ngFor="let photoType of photosTypeArray; let i = index">
              <app-choose-card [formGroupName]="i" [form]="photoType" ref="selected" [indexRef]="i" [multiple]="true"
                               [imgSrc]="photoType.value.type.imgSrc" [title]="photoType.value.type.title"
                               [text]="photoType.value.type.text" alignRef="text-center">
              </app-choose-card>
            </div>
          </div>
        </div>
      </form>

      <form [formGroup]="formSelectProduct" *ngIf="formSelectProduct === steps[activeStepIndex].form">
        <div>
          <h2 class="text-black mt-4 mt-lg-0 mb-4">
            Select type of products - (Can select more than one )
          </h2>
          <app-validation-message [control]="formSelectProduct.get('productsType')" label="Type of Products">
          </app-validation-message>
          <div class="row mb-5">
            <div class="col-12 col-lg-8 offset-lg-2 col-xxl-6 offset-xxl-3">
              <div class="card">
                <div class="row">
                  <ng-container formArrayName="productsType">
                    <div class="col-6 col-lg-3" [ngClass]="{
                        'd-none': productType.value.type === 'Other'
                      }" *ngFor="
                        let productType of productsTypeArray;
                        let i = index
                      ">
                      <ng-container [formGroupName]="i">
                        <input type="checkbox" class="d-none" formControlName="selected" [attr.name]="'productType'"
                               [id]="'productType-' + i" [value]="productType.value.type.title"/>
                        <label class="pill mb-4" [for]="'productType-' + i">
                          {{ productType.value.type.title }}
                        </label>
                      </ng-container>
                    </div>
                  </ng-container>
                  <div class="col-12">
                    <input type="text" class="form-control like-a-pill mb-4" formControlName="othersProduct"
                           placeholder="Other..."/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <form [formGroup]="formImageDimension" *ngIf="formImageDimension === steps[activeStepIndex].form">
        <div>
          <h2 class="text-black mt-4 mt-lg-0 mb-4">Image Dimensions</h2>
          <app-validation-message [control]="formImageDimension.get('dimensionsType')" label="Image Dimension">
          </app-validation-message>
          <div class="row mb-5">
            <div class="col-12 col-lg-8 offset-lg-2 col-xxl-6 offset-xxl-3">
              <div class="card">
                <div class="row">
                  <ng-container>
                    <div class="col-6 col-lg-3" [ngClass]="{
                      'd-none': dimension === 'Other'
                    }" *ngFor="let dimension of dimensions$ | async">
                      <input type="radio" class="d-none" formControlName="dimensionsType" [attr.name]="'dimensionType'"
                             [id]="dimension" [value]="dimension"/>
                      <label class="pill mb-4" [for]="dimension">
                        {{ dimension }}
                      </label>
                    </div>
                  </ng-container>
                  <div class="col-12">
                    <input type="text" class="form-control like-a-pill mb-4" formControlName="othersDimension"
                           placeholder="Other..."/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <form [formGroup]="formBackground" *ngIf="formBackground === steps[activeStepIndex].form">
        <div>
          <h2 class="text-black mt-4 mt-lg-0 mb-4">
            Color Background & Shadow (choose more than one)
          </h2>
          <app-validation-message [control]="formBackground.get('backgroundsType')" label="Color Background and Shadow">
          </app-validation-message>
          <div class="row mb-5">
            <div class="col-12 col-md-6 col-lg-3 mb-4" formArrayName="backgroundsType"
                 *ngFor="let backgroundType of backgroundsTypeArray; let i = index">
              <app-choose-card [formGroupName]="i" [form]="backgroundType" ref="selected" [indexRef]="i"
                               [multiple]="true" [imgSrc]="backgroundType.value.type.imgSrc"
                               [title]="backgroundType.value.type.title"
                               [text]="backgroundType.value.type.text"
                               [description]="backgroundType.value.type.description"
                               alignRef="text-center">
              </app-choose-card>
            </div>
          </div>
        </div>
      </form>

      <form [formGroup]="formExtras" *ngIf="formExtras === steps[activeStepIndex].form">
        <div>
          <h2 class="text-black mt-4 mt-lg-0 mb-4">Extras</h2>
          <app-validation-message [control]="formExtras.get('extrasType')" label="Extras"></app-validation-message>
          <div class="row mb-5">
            <div class="col-12 col-md-6 col-lg-4 mb-4" formArrayName="extrasType"
                 *ngFor="let extraType of extrasTypeArray; let i = index">
              <app-choose-card [formGroupName]="i" [form]="extraType" ref="selected" [indexRef]="i" [multiple]="true"
                               [imgSrc]="extraType.value.type.imgSrc" [title]="extraType.value.type.title"
                               [text]="extraType.value.type.text" alignRef="text-center">
              </app-choose-card>
            </div>
          </div>
        </div>
      </form>

      <form [formGroup]="formReference" *ngIf="formReference === steps[activeStepIndex].form" class="row">
        <div class="col-12 col-lg-8 offset-lg-2 col-xxl-6 offset-xxl-3">
          <div class="mb-2">
            <h2 class="text-black mt-4 mt-lg-0 mb-4">Reference add link or photos, files</h2>
            <label for="upload" class="form-input label-file text-start w-100 mb-3">
              Click to upload image or video
            </label>
            <input id="upload" class="d-none" type="file" (change)="onFileSelected($event)" multiple='multiple'/>

            <ul class="list-unstyled " formArrayName="referenceFile">

              <li class="file-list" *ngFor="let item of referenceFile.controls;let i = index">

                <div class="d-flex justify-content-start gap-2">
                  <app-media-preview [file]="item.value.file"></app-media-preview>
                  <div class="text-start text-truncate">
                    <strong>{{item.value.file.name}}</strong>
                    <p>{{item.value.file.size}}</p>

                  </div>
                  <button (click)='removerItem(i)' class="btn-close ms-auto"></button>
                </div>


              </li>


            </ul>
            <app-validation-message [control]="formReference.get('referenceFile')"
                                    label="reference File"></app-validation-message>
            <input type="text" class="form-input w-100 mb-4 mt-2" formControlName="referenceLink" placeholder="Paste link"/>
          </div>
          <div class="mb-3">
            <h2 class="text-black mt-4 mt-lg-0 mb-4">Briefing</h2>
            <textarea class="form-input textarea-input w-100" formControlName="briefing"
                      placeholder="Describe your demand..."></textarea>
          </div>
        </div>
      </form>
    </ng-container>
  </article>
</section>
<div class="d-flex p-3 justify-content-between justify-content-lg-end ap-3 btn-form align-content-center">
  <button type="button" class="btn btn-lg btn-outline-black btn-back" (click)="backStep()"
          [ngClass]="{ disabled: activeStepIndex === 0 }">
    Back
  </button>
  <button type="button" class="btn btn-lg btn-black btn-next" (click)="nextStep()"
          [ngClass]="{ 'd-none': activeStepIndex + 1 >= steps.length }">
    Next
  </button>

  <button type="button" class="btn btn-lg btn-black btn-next text-nowrap" [disabled]="state === State.LOADING"
          (click)="onSubmit()" [ngClass]="{
      'd-none': activeStepIndex + 1 < steps.length,
      'd-inline': activeStepIndex + 1 >= steps.length
    }">
    Proceed to Review
  </button>
</div>
