import {Component} from '@angular/core';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent {
  works_features = [
    {
      img_src: 'assets/images/eshoots-products.jpeg',
      title: 'Free consultation! ',
      text: `We will decide together which of our services is compatible for your business/brand needs. Whether it's a 50 product project or a 500 product project We will give you all the attention and professionalism you need.`
    },
    {
      img_src: 'assets/images/eshoots-website.jpeg',
      title: 'Send the product!',
      text: `Confirm the image. Testing and filming begins.`
    },
    {
      img_src: 'assets/images/eshoots-fashion.jpeg',
      title: 'Up to 48 hours of footage in your home! ',
      text: 'With a commitment of 200 products'
    }
  ];
}
