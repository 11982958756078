import {Component, OnInit} from '@angular/core';
import {AuthService, User} from '../../services/auth.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  show = false;
  login = true;
  user$: Observable<User | null>;

  constructor(private authService: AuthService, private router: Router, private toastrService: ToastrService) {
  }

  ngOnInit(): void {
    this.user$ = this.authService.user$;
  }

  signOut(): void {
    this.authService.signOut();
    this.toastrService.success('You have been logged out!');
    this.router.navigate(['/']);
  }
}
