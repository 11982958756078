<section>
  <div
    class="container mt-5 d-flex flex-column justify-content-center align-content-center"
  >
    <div>
      <div *ngIf="message">
        <h2>Success!</h2>
        <p>{{message}}</p>
        <a [routerLink]="['/']"  class="btn btn-outline-black " >Back to
          home</a>
      </div>
      <form (ngSubmit)="onSubmit()" *ngIf="!message" [formGroup]="formContact" class="row">
        <div class="col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
          <div class="card rounded-3 mb-4">
            <h2 class="pt-3 ps-3">Contact</h2>
            <div class="card-body">
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control icon icon-user"
                  placeholder="full name"
                  formControlName="fullName"
                />
              <app-validation-message [control]="formContact.get('fullName')" errorStyle="inline" label="Full name"></app-validation-message>
              </div>
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control icon icon-phone"
                  placeholder="phone number"
                  formControlName="phoneNumber"
                />
                <app-validation-message [control]="formContact.get('phoneNumber')" errorStyle="inline" label="Phone Number"></app-validation-message>
              </div>

              <div class="mb-3">
                <input
                  type="email"
                  class="form-control icon icon-email"
                  placeholder="email"
                  formControlName="email"
                />
                <app-validation-message [control]="formContact.get('email')" errorStyle="inline" label="Email"></app-validation-message>
              </div>
              <div class="mb-3 input-password">
                <textarea
                  class="form-control"
                  formControlName="message"
                  placeholder="message"
                  rows="3"
                ></textarea>
                <app-validation-message [control]="formContact.get('message')" errorStyle="inline" label="Message"></app-validation-message>
              </div>
            </div>
          </div>
          <div class="text-center mt-5 mb-5">
            <button type="submit" class="btn btn-lg btn-black btn-next w-50" [disabled]="isLoading">
              {{isLoading ? 'Sending your message' : 'Send Message'}}
            </button>
          </div>
        </div>
      </form>
    </div>

  </div>

</section>
