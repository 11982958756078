<ng-container *ngIf="control && control.touched && control.errors">
  <div

    class=""
    *ngFor="let message of control.errors | validationMessage: label"
    [ngClass]="{inline: errorStyle === 'inline','text-red-600 bg-red-50 rounded p-3 mb-4 fw-bold mx-auto': errorStyle === 'default'}"
  >
    {{ message }}
  </div>
</ng-container>
