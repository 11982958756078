import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, map, Observable, of} from 'rxjs';
import {Apollo, gql} from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class AppService {

  constructor(private httpClient: HttpClient, private apollo: Apollo) {
  }

  download(url: string): void {
    this.httpClient.get(url, {responseType: 'blob'}).pipe(
      map((e) => {
        return URL.createObjectURL(e);
      })
    ).subscribe((objectUrl) => {
      window.open(objectUrl);
    });
  }

  contact(formSendMailInput: {
    fullName: string
    email: string
    phoneNumber: string
    message: string
  }): Observable<string | null> {
    const FORM_SEND_EMAIL = gql`
      mutation formSendMail($formSendMailInput: FormSendMailInput!) {
        formSendMail(FormSendMailInput: $formSendMailInput)
      }
    `;
    return this.apollo
      .mutate<{ formSendMail: string }>({
        mutation: FORM_SEND_EMAIL,
        variables: {
          formSendMailInput,
        },
      })
      .pipe(
        catchError((err) => {
          return of(null);
        }),
        map((result) => {
          if (!result || !result.data) {
            return null;
          }
          return result.data.formSendMail;
        })
        
      );
  }
}
