<div class="container mt-3">

  <h1 class="fs-3">My orders</h1>
  <div class="d-grid gap-3">
    <ng-container *ngIf="orders$ | async as orders; else loading">
      <app-order-item *ngFor="let order of orders" [order]="order"></app-order-item>
      <div *ngIf="!orders.length" class="text-center d-flex flex-column gap-2 align-items-center p-5">
        <span class="material-icons display-1 text-muted">hide_image</span>
        <h1>You haven't created any order</h1>
        <p>Click below and get the best photos of your product</p>
        <button class="btn btn-black" [routerLink]="['/order']">Book Now</button>
      </div>
    </ng-container>
  </div>

  <ng-template #loading>
    <app-order-item [skeleton]="true"></app-order-item>
    <app-order-item [skeleton]="true"></app-order-item>
    <app-order-item [skeleton]="true"></app-order-item>
    <app-order-item [skeleton]="true"></app-order-item>
  </ng-template>

</div>
