import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../services/order.service';
import {OrderInputType, OrderType} from '../../models/order.model';
import { State } from '../../models/state';
import { catchError, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-review-order',
  templateUrl: './review-order.component.html',
  styleUrls: ['./review-order.component.scss'],
})
export class ReviewOrderComponent implements OnInit {
  order: OrderInputType;
  state = State.INITIAL;
  protected readonly State = State;
  dataUrl: any;

  constructor(
    private orderService: OrderService,
    private toastrService: ToastrService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const order = this.orderService.getOrder();
    if (!order) {
      return;
    }
    this.order = order;

    if (this.order.extras.image) {
      let type = '';
      switch (true) {
        case this.order.extras.image.type.startsWith('image/'):
          type = 'image';
          break;

        case this.order.extras.image.type.startsWith('video/'):
          type = 'video';
          break;
      }

      this.dataUrl = {
        link: this.sanitizer.bypassSecurityTrustResourceUrl(
          URL.createObjectURL(this.order.extras.image)
        ),
        contentType: this.order.extras.image.type,
        type: type,
      };
    }
  }

  createOrder(): void {
    this.state = State.LOADING;
    this.orderService
      .createOrder()
      .pipe(
        catchError((err) => {
          return of(false);
        })
      )
      .subscribe((result) => {
        this.state = State.LOADED;
        if (result) {
          this.toastrService.success('Order created successfully');
          this.router.navigate(['/my-orders']);
        }
      });
  }
}
