<footer class="py-3">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg mb-2 mb-lg-0">
                <address class="text-light-gray text-center text-lg-left mb-0">
                    Hasadna 8, Holon 5881515 Israel | <a href="mailto:office@e-shoots.com" target="_blank" class="text-light-gray">office@e-shoots.com</a> | 054-205-1778
                </address>
            </div>
            <div class="col-12 col-lg-auto text-center text-lg-right">
                <a routerLink="/terms" class="text-light-gray me-4">
                    Terms
                </a>
                <a routerLink="/privacy" class="text-light-gray">
                    Privacy
                </a>
            </div>
        </div>
    </div>
</footer>
