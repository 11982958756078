<div class="container min-vh-100 d-flex flex-column justify-content-center">
  <div class="row d-flex flex-column justify-content-center align-content-center">
    <div class="col-12 col-lg-6 col-xl-6">
      <div class="card rounded-3">
        <div class="card-body">
          <div class="text-center mb-4">
            <img class="brand" src="../../../assets/images/logo-eshoots.png" alt="logo"/>
          </div>

          <form class="col-12 col-md-8 col-xl-8 mx-auto" [formGroup]="form" *ngIf="!message">
            <h4 class="mb-4 text-center">Create new Password</h4>
            <div class="mb-3">
              <label for="">Password</label>
              <div class="input-password">
                <input
                  type="password"
                  formControlName="newPassword"
                  class="form-control"
                  #password
                />
                <i
                  class="input-password__icon"
                  (click)="togglePassword(password)"
                ></i>
              </div>
              <app-validation-message errorStyle="inline" [control]="form.get('newPassword')" label="Password"></app-validation-message>

            </div>
            <div class="mb-4">
              <label for="">Confirm Password</label>
              <div class="input-password">
                <input type="password" class="form-control" formControlName="confirmPassword"/>
              </div>

              <app-validation-message errorStyle="inline" [control]="form.get('confirmPassword')" label="Confirm Password"></app-validation-message>
            </div>
            <button type="submit" class="btn btn-black d-block mx-auto mb-2 w-50 " (click)="resetPassword()" [disabled]="isLoading">
              {{isLoading ? 'Sending' :  'Reset Password'}}
            </button>
          </form>
          <ng-container *ngIf="message">
            <h2 class="text-center"> Success!</h2>
            <p class="my-4 text-center">{{message}}</p>
             <a [routerLink]="['/auth/login']"  class="btn btn-outline-black d-block mx-auto w-50 " >Back to
              login</a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
