import {map, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Component, Input, OnChanges} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class MediaComponent implements OnChanges {
  @Input() link: string = '';

  private safeLink$ = new BehaviorSubject(this.link);
  dataUrl$ = this.safeLink$.pipe(switchMap((url) => this.loadImage(url)));

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnChanges(): void {
    this.safeLink$.next(this.link);
  }

  private loadImage(url: string): Observable<any> {
    return this.httpClient.get(url, {responseType: 'blob'}).pipe(
      map((e) => {
        let type = '';
        switch (true) {
          case e.type.startsWith('image/'):
            type = 'image';
            break;

          case e.type.startsWith('video/'):
            type = 'video';
            break;
        }

        return {
          link: this.sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(e)
          ),
          contentType: e.type,
          type: type,
        };
      })
    );
  }
}
