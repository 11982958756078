import { Observable } from 'rxjs';
import { OrderService } from 'src/app/services/order.service';
import { UserType } from 'src/app/models/users.model';
import { Component, Input, OnInit } from '@angular/core';
import { OrderType } from 'src/app/models/order.model';

@Component({
  selector: 'app-client-item',
  templateUrl: './client-item.component.html',
  styleUrls: ['./client-item.component.scss'],
})
export class ClientItemComponent implements OnInit {
  public isCollapsed = true;
  @Input() client: UserType;
  clientOrders$: Observable<OrderType[]>;

  constructor(private orderService: OrderService) {}

  ngOnInit(): void {
    if (!this.client.id) {
      return;
    }

    this.clientOrders$ = this.orderService.getOrdersByUserId({
      userId: this.client.id,
    });
  }
}
