<div class="container min-vh-100 d-flex flex-column justify-content-center">
  <div class="row d-flex flex-column justify-content-center align-content-center">
    <div class="col-12 col-lg-6 col-xl-6">
      <div class="card rounded-3">
        <div class="card-body">
          <div class="text-center mb-4">
            <img class="brand" src="../../../assets/images/logo-eshoots.png" alt="logo" />

            <form class="col-12 col-md-8 col-xl-8 mx-auto" [formGroup]="form" *ngIf="!message">
              <h4 class="mt-1 pb-1">Forgot Password?</h4>
              <div class="mb-4">
                <input type="email" placeholder="Email" class="form-control" formControlName="email" />
              </div>
              <button type="submit" class="btn btn-black d-block mx-auto mb-2 w-50 " (click)="forgotPassword()" [disabled]="isLoading">
                {{isLoading ? 'Sending Link' :  'Reset Password'}}
              </button>
              <a [routerLink]="['/auth/login']"  class="btn btn-outline-black d-block mx-auto w-50 " >Back to
                login</a>
            </form>
            <ng-container *ngIf="message">
              <h2> Success!</h2>
              <p class="my-4">{{message}}</p>
              <a [routerLink]="['/auth/login']"  class="btn btn-outline-black d-block mx-auto w-50 " >Back to
                login</a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

